import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { SnowAreaReportViewModel } from '../models/SnowAreaReportViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SnowlogService {

  private snowlogUrl = '/api/snowlog/';
  private snowAreaUrl = '/api/snowArea/';

  constructor(private http: HttpClient) { }

  save(vm: any): Observable<any> {
    console.log(vm);
    return this.http.post<any>(this.snowlogUrl + 'create', vm, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  update(editViewModel: any): Observable<any> {
    return this.http.put<any>(this.snowlogUrl + 'update', editViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  delete(snowLogAreaId: number): Observable<any> {
    return this.http.delete<any>(this.snowlogUrl + 'delete?snowLogAreaId=' + snowLogAreaId, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  printReport(printReportViewModel: SnowAreaReportViewModel): Observable<any> {
    return this.http.post<SnowAreaReportViewModel>(this.snowAreaUrl + 'report', printReportViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

}
