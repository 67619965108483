import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ToastrService } from 'ngx-toastr';
import { ProjectControlPostService } from '@app/services/projectControlPost.service';
import { ProjectControlPostViewModel } from '@app/models/ProjectControlPostViewModel';
import { AuthenticationService } from '../../../../../services/authentication.service';
import { DatepickerDefaultOptions } from '../../../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-project-control-post-form',
  templateUrl: './project-control-post-form.component.html',
  styleUrls: ['./project-control-post-form.component.css']
})
export class ProjectControlPostFormComponent implements OnInit {

  @Input() projectControlPost: ProjectControlPostViewModel
  @Input() users: SimpleViewModel[]
  @Output() onDeleted = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private projectControlPostService: ProjectControlPostService,
    private auth: AuthenticationService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) {
    this.formGroup = this.formBuilder.group({
      'id': [''],
      'text': [''],
      'date': [''],
      'userId': [''],
      'projectControlItemId': [''],
      'controlItemName': [''],
      'userName': [''],
      'image': ['']
    })
  }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  defaultValues = {
    id: null,
    text: null,
    date: null,
    userId: null,
    projectControlItemId: null,
    controlItemName: null,
    userName: null,
    image: null
  }

  formGroup: FormGroup
  get form() { return this.formGroup.controls; }

  onSubmit() {
    this.update();
  }

  onDateChange(date: any) {

  }

  adjustForTimezone(date: Date): Date {
    var timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - timeOffsetInMS);
    return date
  }

  update(): void {
    //timezone fix
    this.formGroup.controls['date'].patchValue(this.adjustForTimezone(new Date(this.formGroup.controls['date'].value)))
    this.projectControlPostService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Kontrollen har ändrats.')
      },
      (error) => {
        this.toastr.error('Det gick inte att ändra kontrollen.');
      },
      () => {
        //complete
      }
    );
  }

  remove(): void {
    let id = this.projectControlPost.id
    this.projectControlPostService.delete(id).subscribe(
      () => {
        this.toastr.success('Kontrollen har tagits bort.')
        this.onDeleted.emit(id)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort kontrollen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
    let data = this.projectControlPost
    let formValues = this.defaultValues
    //merge edit values with defaults
    formValues = { ...this.defaultValues, ...data }
    this.formGroup.reset(formValues)
  }

  ngAfterViewInit() {

  }

}
