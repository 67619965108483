
// Auto generated by typewriter


export class ProjectReportViewModel {
    
    id: number = 0;
    fromDate: Date = null;
    toDate: Date = null;
    includeInternalMessages: boolean = false;
    markAsHandled: boolean = false;
    includeHistory: boolean = false;
    includeBillable: boolean = true;
    sortByDate: boolean = false;
    hideProjectCosts: boolean = false;
}
