import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from '@app/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ProjectControlPostViewModel } from '@app/models/ProjectControlPostViewModel';

@Component({
  selector: 'app-project-modal-control-details',
  templateUrl: './project-modal-control-details.component.html',
  styleUrls: ['./project-modal-control-details.component.css']
})
export class ProjectModalControlDetailsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) { }

  id: number
  modalName: string = 'projectModalControlDetails'
  projectControlPosts: ProjectControlPostViewModel[]
  users: SimpleViewModel[]

  clear(): void {
    this.id = null
    this.projectControlPosts = []
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  getProjectControlPosts(): void {
    this.projectService.getControlPosts(this.id).subscribe(
      (projectControlPosts) => {
        this.projectControlPosts = projectControlPosts
      },
      (error) => {
        this.toastr.error('Det gick inte att hämta kontrollerna.');
      },
      () => {
        //complete
      }
    );
  }

  getUsers(): void {
    this.http.get<SimpleViewModel[]>("/api/user/getAll")
      .subscribe(data => {
        this.users = data;
      });
  }

  onProjectControlPostDeleted(id: number) {
    let index = this.projectControlPosts.findIndex(x => x.id === id)
    this.projectControlPosts.splice(index, 1)
  }

  ngOnInit() {
    this.getUsers()
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.id = modal.getData()
      this.getProjectControlPosts()
    });
  }
}
