import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { DataTableDirective } from 'angular-datatables';
import { PersonalTimeDataTableViewModel } from '@app/models/PersonalTimeDataTableViewModel';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { HttpClient } from '@angular/common/http';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
    selector: 'app-user-time-datatable',
    templateUrl: './user-time-datatable.component.html',
    styleUrls: ['./user-time-datatable.component.css']
})
export class UserTimeDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private route: ActivatedRoute,
        private dataTableOptions: DatatableDefaultOptions,
        private http: HttpClient,
        private datePickerDefaultOptions: DatepickerDefaultOptions
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    timeLogDtOptions: DataTables.Settings = {};
    timeLogs: PersonalTimeDataTableViewModel[]
    modalEdit: string = 'userTimeModalEdit';
    modalRemove: string = 'userTimeModalRemove'
    filterFromDate: Date
    filterToDate: Date
    showDateFilters: boolean = false
    userId: number = null
    datepickerConfig = this.datePickerDefaultOptions.defaults

    toggleDatePickers(): void {
        this.showDateFilters = !this.showDateFilters
    }

    dateChanged() {
        this.reloadDatatable()
    }

    edit(timeLog: PersonalTimeDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(timeLog.id), this.modalEdit);
        this.ngxSmartModalService.open(this.modalEdit);
    }

    remove(timeLog: PersonalTimeDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(timeLog.id), this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    ngOnInit() {
        this.userId = Number(this.route.snapshot.paramMap.get('id'));
        this.initDatatable()
    }

    ngAfterViewInit() {
        this.moveCustomFilters()
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    }

    moveCustomFilters(): void {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
                let datatableWrapper = (dtInstance.table('table').container())
                let dtId = $(datatableWrapper).attr('id')
                var closestFilter = $('#' + dtId).siblings('.dt-filter')
                $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
            })
        )
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.timeLogDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                        + (self.userId ? "&userId=" + self.userId : "")
                        + (self.filterFromDate ? "&fromDate=" + self.filterFromDate.toJSON() : "")
                        + (self.filterToDate ? "&toDate=" + self.filterToDate.toJSON() : "")

                    self.http.get<DataTablesResponse>(`api/personalTime/DatatableByUser?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.timeLogs = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
                columns: [
                    { data: 'timeTypeName' },
                    { data: 'time' },
                    { data: 'date' },
                    { data: 'note' }
                ]
            }
        }
    }

}
