
// Auto generated by typewriter


export class SingleProjectStatsViewModel {
    
    budgetHours: number = 0;
    workedHours: number = 0;
    sumInvoiced: number = 0;
    sum: number = 0;
    timeLogsSum: number = 0;
    timeLogArticlesSum: number = 0;
    gravelLogsSum: number = 0;
    projectCostsSum: number = 0;
    timeLogsSumInvoiced: number = 0;
    timeLogArticlesInvoiced: number = 0;
    timeLogArticlesSumInvoiced: number = 0;
    gravelLogsSumInvoiced: number = 0;
    projectCostsSumInvoiced: number = 0;
}
