import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DatatableDefaultOptions } from '../../DatatableDefaultOptions';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from '../../models/DataTablesResponse';
import { DataTablesRequest, DataTableOrder } from '../../models/DataTablesRequest';
import { ServiceLogDatatableViewModel } from '../../models/ServiceLogDatatableViewModel';
import { ServiceLogEditViewModel } from '../../models/ServiceLogEditViewModel';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-service-logs-datatable',
    templateUrl: './service-logs-datatable.component.html',
    styleUrls: ['./service-logs-datatable.component.css']
})
export class ServiceLogsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private http: HttpClient,
        private auth: AuthenticationService
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    serviceLogDtOptions: DataTables.Settings = {};
    serviceLogs: ServiceLogDatatableViewModel[]
    modalEdit: string = 'serviceLogEditModal'
    modalRemove: string = 'serviceLogRemoveModal'

    //machineId sould be set by parent component
    machineId: number = null

    edit(serviceLog: ServiceLogDatatableViewModel): void {
        this.ngxSmartModalService.resetModalData(this.modalEdit)

        this.http.get<ServiceLogEditViewModel>("/api/serviceLog/update/" + serviceLog.id)
            .subscribe(data => {
                this.ngxSmartModalService.setModalData(data, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            });
    }

    remove(serviceLog: ServiceLogDatatableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(serviceLog.id), this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    ngOnInit() {
        this.initDatatable()
    }

    ngAfterViewInit() {
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    }

    //getDefaultSortOrder(): DataTableOrder[] {
    //  let dataTableOrder: DataTableOrder = new DataTableOrder
    //  dataTableOrder.column = 1
    //  dataTableOrder.dir = "asc"
    //  let order: DataTableOrder[] = [];
    //  order[0] = dataTableOrder
    //  return order;
    //}

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.serviceLogDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    //dataTablesParameters.order = this.getDefaultSortOrder();
                    let request = JSON.stringify(dataTablesParameters)
                        + (self.machineId ? "&machineId=" + self.machineId : "")
                    self.http.get<DataTablesResponse>(`api/serviceLog/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.serviceLogs = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'userName' },
                { data: 'mileage' },
                { data: 'time' },
                { data: 'date' },
                { data: 'note' }
            ]
        }
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

}
