import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Label, Color, BaseChartDirective, MultiDataSet } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { SingleProjectStatsViewModel } from '@app/models/SingleProjectStatsViewModel';

@Component({
  selector: 'app-project-chart',
  templateUrl: './project-chart.component.html',
  styleUrls: ['./project-chart.component.css']
})
export class ProjectChartComponent implements OnInit {

  constructor() { }
  public chartType: ChartType = 'doughnut';
  public chartLabels: Label[] = ['Arbetad', 'Budgeterad'];
  public chartData: MultiDataSet;
  public options: ChartOptions = {
    legend: { position: 'right' }
  }

  ngOnInit(): void {

  }
}
