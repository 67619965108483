import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ControlService } from '@app/services/control.service';

@Component({
  selector: 'app-control-remove-modal',
  templateUrl: './control-remove-modal.component.html',
  styleUrls: ['./control-remove-modal.component.css']
})
export class ControlRemoveModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private controlService: ControlService
  ) { }

  controlId: number
  modalName: string = 'controlRemoveModal'

  clear(): void {
    this.controlId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.controlId) {
      return;
    }

    this.controlService.delete(this.controlId).subscribe(
      () => {
        this.toastr.success('Kontrollen har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort kontrollen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.controlId = modal.getData()
    });
  }

}
