import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css']
})
export class MachinesComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
  }

}
