import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../services/project.service';
import { HttpClient } from '@angular/common/http';
import { ProjectCostDataTableViewModel } from '../../models/ProjectCostDataTableViewModel';
import { ProjectDocumentsViewModel } from '../../models/ProjectDocumentsViewModel';
import { DocumentViewModel } from '../../models/DocumentViewModel';
import { MessageService } from '../../services/message.service';
import { Messages } from '../../models/enums/Messages';

@Component({
  selector: 'app-project-cost-document-edit-modal',
  templateUrl: './project-cost-document-edit-modal.component.html',
  styleUrls: ['./project-cost-document-edit-modal.component.css']
})
export class ProjectCostDocumentEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private http: HttpClient,
    private messageService: MessageService
  )
  {}

  @Output() messageEvent = new EventEmitter<string>();
  projectId: number
  projectCost: ProjectCostDataTableViewModel
  documents: DocumentViewModel[]
  modalName: string = 'projectCostDocumentEditModal';

  defaultValues = {
    costId: null,
    documentId: null,
  }

  clear(): void {
    this.projectCost = null
    this.projectId = null
    this.ngxSmartModalService.get('projectCostDocumentEditModal').removeData();
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.projectService.saveCostDocument(this.projectCost.id, this.projectCost.documentId).subscribe(
      () => {
        this.toastr.success('Kostnadsraden sparad.')
        this.messageService.sendMessage(Messages.ReloadDocuments)
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.toastr.error('Det gick inte att spara.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  getDocuments(): void {
    this.http.get<ProjectDocumentsViewModel>("/api/project/documents?id=" + this.projectId)
      .subscribe(data => {
        this.documents = data.documents;
      });
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectCostDocumentEditModal').onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var data = modal.getData()
      this.projectCost = data.projectCost
      this.projectId = data.projectId
      this.getDocuments()
    });
  }
}
