﻿
// Auto generated by typewriter

import { ControlItemViewModel } from './ControlItemViewModel';
export class ControlEditViewModel {
    
    id: number = null;
    name: string = null;
    controlItems: ControlItemViewModel[] = [];
}
