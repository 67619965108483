import { Component, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { svLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TravelLogEditViewModel } from '@app/models/TravelLogEditViewModel';
import { DataTableDirective } from 'angular-datatables';
import { TravelLogDataTableViewModel } from '@app/models/TravelLogDataTableViewModel';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
    selector: 'app-user-travel-logs-datatable',
    templateUrl: './user-travel-logs-datatable.component.html',
    styleUrls: ['./user-travel-logs-datatable.component.css']
})
export class UserTravelLogsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private dataTableOptions: DatatableDefaultOptions,
        private datePickerDefaultOptions: DatepickerDefaultOptions
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    travelLogDtOptions: DataTables.Settings = {};
    travelLogs: TravelLogDataTableViewModel[]
    filterFromDate: Date
    filterToDate: Date
    showDateFilters: boolean = false
    modalEdit: string = 'userTravellogModalEdit'
    modalRemove: string = 'userTravellogModalRemove'
    userId: number = null
    datepickerConfig = this.datePickerDefaultOptions.defaults

    toggleDatePickers(): void {
        this.showDateFilters = !this.showDateFilters
    }

    dateChanged() {
        this.reloadDatatable()
    }

    edit(travelLog: TravelLogDataTableViewModel): void {
        this.http.get<TravelLogEditViewModel>("/api/travelLog/update/" + travelLog.id)
            .subscribe(data => {
                this.ngxSmartModalService.setModalData(data, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            });
    }

    remove(travelLog: TravelLogDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(travelLog.id), this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    ngOnInit() {
        this.userId = Number(this.route.snapshot.paramMap.get('id'));
        this.initDatatable();
    }

    ngAfterViewInit() {
        this.moveCustomFilters()
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    }

    moveCustomFilters(): void {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
                let datatableWrapper = (dtInstance.table('table').container())
                let dtId = $(datatableWrapper).attr('id')
                var closestFilter = $('#' + dtId).siblings('.dt-filter')
                $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
            })
        )
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.travelLogDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                        + (self.userId ? "&userId=" + self.userId : "")
                        + (self.filterFromDate ? "&fromDate=" + self.filterFromDate.toJSON() : "")
                        + (self.filterToDate ? "&toDate=" + self.filterToDate.toJSON() : "")

                    self.http.get<DataTablesResponse>(`api/TravelLog/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.travelLogs = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
                columns: [
                    { data: 'from' },
                    { data: 'to' },
                    { data: 'km' },
                    { data: 'mileage' },
                    { data: 'regNumber' },
                    { data: 'date' }
                ]
            }
        }
    }
}
