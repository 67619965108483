import { Component } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { svLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private config: NgSelectConfig, private localeService: BsLocaleService) {
    this.config.notFoundText = 'Hittade inget.'
    this.config.loadingText = 'Laddar...'
    this.config.clearAllText = 'Rensa alla'

    defineLocale('sv', svLocale);
    this.localeService.use('sv')
  }

  title = 'Söderqvist Gräv'
}
