import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ProjectStatsViewModel } from '@app/models/ProjectStatsViewModel';

@Component({
  selector: 'app-project-stats',
  templateUrl: './project-stats.component.html',
  styleUrls: ['./project-stats.component.css']
})
export class ProjectStatsComponent implements OnInit {

  constructor(private http: HttpClient) { }

  projectStats = new ProjectStatsViewModel;

  ngOnInit() {
    this.http.get<ProjectStatsViewModel>("/api/project/stats")
      .subscribe(data => {
        this.projectStats = data;
      });
  }

}
