import { Component, OnInit } from '@angular/core';
import { ControlService } from '@app/services/control.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ControlEditViewModel } from '@app/models/ControlEditViewModel';
import { ControlItemViewModel } from '@app/models/ControlItemViewModel';

@Component({
    selector: 'app-control-edit-modal',
    templateUrl: './control-edit-modal.component.html',
    styleUrls: ['./control-edit-modal.component.css']
})
export class ControlEditModalComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private controlService: ControlService
    ) { }

    ngOnInit() {
        this.newControlItem.name = ''
        this.newControlItem.hasImage = true
        this.newControlItem.hasText = true
        this.controlEditForm = this.formBuilder.group({
            id: [''],
            name: ['', [Validators.required, Validators.maxLength(64)]]
        });
    }
    newControlItem: ControlItemViewModel = new ControlItemViewModel()
    controlItems: ControlItemViewModel[] = []
    controlEditForm: FormGroup
    modalName: string = 'controlEditModal';

    get form() {
        return this.controlEditForm.controls;
    }

    resetForm() {
        const defaultValues = {
            id: null,
            name: ''
        };
        this.controlItems = []
        this.controlEditForm.reset(defaultValues);
    }

    isNew: boolean = false;

    clear(): void {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.resetForm();
        this.newControlItem.hasText = true
        this.newControlItem.hasImage = true
        this.newControlItem.name = '';
        this.newControlItem.controlId = null
        this.newControlItem.id = null
    }

    close(): void {
        this.clear();
        this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
        this.ngxSmartModalService.close(this.modalName);
    }

    closeModalWithSuccess() {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.ngxSmartModalService.close(this.modalName);
    }

    save(): void {
        let vm = new ControlEditViewModel()
        vm = this.controlEditForm.value
        vm.controlItems = this.controlItems

        this.controlService.save(vm).subscribe(
            () => {
                this.toastr.success('Kontrollen har sparats.');
                this.clear()
                this.closeModalWithSuccess()
            },
            (error) => {
                this.toastr.error('Det gick inte att spara kontrollen.');
            },
            () => {
                //complete
            }
        );
    }

    update(): void {
        let vm = new ControlEditViewModel()
        vm = this.controlEditForm.value
        vm.controlItems = this.controlItems

        this.controlService.update(this.controlEditForm.value).subscribe(
            () => {
                this.toastr.success('Kontrollen har sparats.');
                this.closeModalWithSuccess()
            },
            (error) => {
                this.toastr.error('Det gick inte att uppdatera kontrollen.');
            },
            () => {
                //complete
            }
        );
    }

    addNewControlItem(controlItem: ControlItemViewModel) {
        if (!controlItem.name || controlItem.name.length < 1) {
            return
        }
        let copy = { ...controlItem };
        this.newControlItem.controlId = null
        this.newControlItem.id = null
        this.newControlItem.hasImage = true
        this.newControlItem.hasText = true
        this.newControlItem.name = ''
        let controlId = this.controlEditForm.controls['id'].value
        copy.controlId = controlId
        this.controlItems.push(copy)
    }

    removeControlItem(controlItem: ControlItemViewModel, index: number): void {
        this.controlItems.splice(index, 1)
    }

    updateControlItem(controlItem: ControlItemViewModel) {
        console.log("updating");
        console.log(controlItem)
        if (controlItem.id) {

            this.controlService.updateControlItem(controlItem).subscribe(
                () => {
                },
                (error) => {
                    this.toastr.error('Det gick inte att uppdatera kontrollraden.');
                },
                () => {
                    //complete
                }
            );
        }

    }

    onSubmit() {
        if (this.isNew === true) {
            this.save()
        } else {
            this.update();
        }
    }

    ngAfterViewInit() {
        this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
            let modalData: ControlEditViewModel = modal.getData();
            if (!modalData || !modalData.id) {
                this.isNew = true
                this.resetForm()
            }
            else {
                this.isNew = false;
                this.controlItems = modalData.controlItems
                delete modalData.controlItems //don't map the controlitems to the form
                this.controlEditForm.setValue(modalData)
            }
        })
    }

}
