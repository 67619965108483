﻿
// Auto generated by typewriter


export class UserProfileViewModel {
    
    id: number = 0;
    userName: string = null;
    roles: number[] = [];
    email: string = null;
    employmentNumber: string = null;
    firstName: string = null;
    lastName: string = null;
    phoneNumber: string = null;
}
