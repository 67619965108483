import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from "@angular/common/http";
import { Routes, Router } from '@angular/router/'
import { ProjectService } from '@app/services/project.service';
import { ProjectEditViewModel } from '@app/models/ProjectEditViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { ProjectDataTableViewModel } from '../../../models/ProjectDataTableViewModel';
import { DataTableDirective } from 'angular-datatables';
import { DatatableDefaultOptions } from '../../../DatatableDefaultOptions';
import { DataTablesRequest } from '../../../models/DataTablesRequest';
import { DataTablesResponse } from '../../../models/DataTablesResponse';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
    selector: 'app-projects-datatable',
    templateUrl: './projects-datatable.component.html',
    styleUrls: ['./projects-datatable.component.css']
})
export class ProjectsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private http: HttpClient,
        private router: Router,
        private projectService: ProjectService,
        private auth: AuthenticationService,
        private dataTableOptions: DatatableDefaultOptions,
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    projectDtOptions: DataTables.Settings = {}
    projects: ProjectDataTableViewModel[]
    childProjects: ProjectDataTableViewModel[] = []
    showHistory: boolean = false
    modalEdit: string = 'projectModalEdit'
    modalRemove: string = 'projectModalRemove'

    ngOnInit() {
        this.initDatatable()
    }

    show(project: ProjectDataTableViewModel): void {
        this.router.navigate(['/project/' + project.id])
    }

    edit(project: ProjectDataTableViewModel): void {
        this.http.get<ProjectEditViewModel>("/api/project/update/" + project.id)
            .subscribe(data => {
                this.ngxSmartModalService.resetModalData(this.modalEdit)
                this.ngxSmartModalService.setModalData(data, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            });
    }

    remove(project: ProjectDataTableViewModel): void {
        this.ngxSmartModalService.resetModalData(this.modalRemove)
        this.ngxSmartModalService.setModalData(project.id, this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove)
    }


    ngAfterViewInit() {
        this.moveCustomFilters()
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
  }

  ngAfterContentInit() {
    this.showHistory = localStorage.getItem('DataTables_showHistory_projects') == "true"
  }

    moveCustomFilters(): void {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
                let datatableWrapper = (dtInstance.table('table').container())
                let dtId = $(datatableWrapper).attr('id')
                var closestFilter = $('#' + dtId).siblings('.dt-filter')
                $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
            })
        )
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
      localStorage.setItem('DataTables_showHistory_projects', this.showHistory.toString())
    }

    shouldShow(project: ProjectDataTableViewModel): boolean {
        return !this.childProjects.some(x => x.id === project.id);
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0;
        this.projectDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                        + "&showHistory=" + self.showHistory
                    self.http.get<DataTablesResponse>(`api/Project/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw

                        self.projects = response.data

                        self.childProjects = []
                        self.projects.forEach(function (project) {
                            if (project.hasChildren) {
                              self.childProjects.push(...project.childProjects)
                            }
                        })

                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: response.draw
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'parentId' },
                { data: 'createdDate' },
                { data: 'id' },
                { data: 'projectNumber' },
                { data: 'responsibleName' },
                { data: 'title' },
                { data: 'tags' },
                { data: 'customerName' },
                { data: 'active' },
                { data: 'billable' }
            ]
        }
    }



}
