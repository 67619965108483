import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '@app/services/project.service';
import { DocumentViewModel } from '@app/models/DocumentViewModel';
import { Subscription, fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';
import { HttpEventType, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Messages } from '../../../../models/enums/Messages';

@Component({
  selector: 'app-project-modal-document',
  templateUrl: './project-modal-document.component.html',
  styleUrls: ['./project-modal-document.component.css']
})
export class ProjectModalDocumentComponent implements OnInit, OnDestroy  {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) { }

  @Output() messageEvent = new EventEmitter<string>();
  canvas: ElementRef;
  canvasEl: HTMLCanvasElement = null
  canvasRenderingContext: CanvasRenderingContext2D;
  drawingSubscription: Subscription;
  data: DocumentViewModel
  isImage: boolean = false
  isDrawing: boolean = false
  saveProgress: number = 0

  getProgressBar(): any {
    var bg = `linear-gradient(to right,#b1f18a ${this.saveProgress}%,white ${this.saveProgress}%)`;
    let styles = {
      'background-image': bg
    };
    return styles;
  }

  clear(): void {
    this.ngxSmartModalService.get('projectModalDocument').removeData()
    this.isDrawing = false
    this.saveProgress = 0
    if (this.drawingSubscription) {
      this.drawingSubscription.unsubscribe()
    }
  }

  remove(): void {
    this.projectService.deleteDocument(this.data.id).subscribe(
      () => {
        this.toastr.success('Dokumentet har tagits bort.');
        this.ngxSmartModalService.get('projectModalDocument').removeData();
        this.ngxSmartModalService.setModalData({ success: true }, 'projectModalDocument');
        this.ngxSmartModalService.close('projectModalDocument');
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort dokumentet.');
      },
      () => {
        //complete
      }
    );
  }

  toggleMark(): void {
    this.projectService.toggleDocumentMark(this.data.id).subscribe(
      () => {
        let newMarkStatus = !this.data.marked
        let message = "Dokumentet har markerats."
        if (newMarkStatus == false) {
          message = "Dokumentet har avmarkerats."
        }
        this.toastr.success(message);
        this.ngxSmartModalService.get('projectModalDocument').removeData();
        this.ngxSmartModalService.setModalData({ success: true }, 'projectModalDocument');
        this.ngxSmartModalService.close('projectModalDocument');
      },
      (error) => {
        this.toastr.error('Det gick inte att ändra dokumentet.');
      },
      () => {
        //complete
      }
    );
  }

  draw(): void {
    this.isDrawing = true
    this.canvasEl = document.getElementById('image-canvas') as HTMLCanvasElement;
    this.canvasRenderingContext = this.canvasEl.getContext('2d');
    this.loadImageToCanvas()
    this.captureEvents();
  }

  save(): void {
    var self = this
    let projectId = this.route.snapshot.paramMap.get('id');
    this.saveProgress = 1 //just to start showing the progressbar
    let originalImage = document.getElementById('document-image') as HTMLImageElement
    var parts = originalImage.src.split('/')
    var originalImageFileName = parts[parts.length - 1].split('?')[0]
    this.canvasEl.toBlob(function (blob) {
      let formData = new FormData();
      formData.append('file', blob, originalImageFileName)
      formData.append('projectId', projectId);
      formData.append('documentId', self.data.id.toString());

      const req = new HttpRequest('POST', 'api/project/uploadDocument', formData, {
        reportProgress: true,
      });

      self.http.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * event.loaded / event.total);
          self.saveProgress = percentDone

        } else if (event instanceof HttpResponse) {
          if (event.status == 200) {
            self.toastr.success('Fil sparad!');
            self.messageEvent.emit(Messages.ReloadDocuments)
          }
        }
      },
        (error) => {
          self.toastr.error('Det gick inte att spara filen!');
        },
        () => {
          //complete
        }
      );
    });
  }

  loadImageToCanvas(): void {
    var self = this
    let img = new Image();
    img.onload = function () {
      self.canvasEl.width = img.width;
      self.canvasEl.height = img.height;
      self.canvasRenderingContext.drawImage(img, 0, 0);
    }
    img.src = this.data.origHref;
  }

  captureEvents() {
    this.drawingSubscription = fromEvent(this.canvasEl, 'mousedown')
      .pipe(
        switchMap(e => {
          return fromEvent(this.canvasEl, 'mousemove').pipe(
            takeUntil(fromEvent(this.canvasEl, 'mouseup')),
            takeUntil(fromEvent(this.canvasEl, 'mouseleave')),
            // pairwise lets us get the previous value to draw a line from the previous point to the current point
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = this.canvasEl.getBoundingClientRect();

        //divide by actual size because the canvas element is scaled with css, to match the mouse position
        var sx = this.canvasEl.scrollWidth / this.canvasEl.width;
        var sy = this.canvasEl.scrollHeight / this.canvasEl.height;

        const prevPos = {
          x: (res[0].clientX - rect.left) / sx,
          y: (res[0].clientY - rect.top) / sy
        };
        const currentPos = {
          x: (res[1].clientX - rect.left) / sx,
          y: (res[1].clientY - rect.top) / sy
        };

        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  drawOnCanvas(prevPos: { x: number; y: number }, currentPos: { x: number; y: number }) {

    if (!this.canvasRenderingContext) {
      return;
    }

    this.canvasRenderingContext.beginPath();

    if (prevPos) {
      this.canvasRenderingContext.moveTo(prevPos.x, prevPos.y);
      this.canvasRenderingContext.lineTo(currentPos.x, currentPos.y);
      this.canvasRenderingContext.lineWidth = 10
      this.canvasRenderingContext.lineCap = 'round';
      this.canvasRenderingContext.strokeStyle = '#dc3545';
      this.canvasRenderingContext.stroke();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectModalDocument').onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.data = modal.getData()
      this.isImage = this.data.mime.startsWith('image/')
    });
  }

  ngOnInit() {
    this.saveProgress = 0
  }

  ngOnDestroy() {
    if (this.drawingSubscription) {
      this.drawingSubscription.unsubscribe();
    }
  }

}
