
// Auto generated by typewriter


export class ProjectMonthReportViewModel {
    
    toDate: Date = null;
    projectCost: boolean = false;
    projectTime: boolean = false;
    projectTimeCost: boolean = false;
    material: boolean = false;
    materialCost: boolean = false;
    gravel: boolean = false;
    gravelCost: boolean = false;
    priceListId: number = 0;
}
