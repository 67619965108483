import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { GravelLogDataTableViewModel } from '../../models/GravelLogDataTableViewModel';
import { DatatableDefaultOptions } from '../../DatatableDefaultOptions';
import { DataTablesResponse } from '../../models/DataTablesResponse';
import { DataTablesRequest } from '../../models/DataTablesRequest';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-gravel-logs-datatable',
  templateUrl: './gravel-logs-datatable.component.html',
  styleUrls: ['./gravel-logs-datatable.component.css']
})
export class GravelLogsDatatableComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private dataTableOptions: DatatableDefaultOptions,
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  gravelLogDtOptions: DataTables.Settings = {};
  gravelLogs: GravelLogDataTableViewModel[]
  showHistory: boolean = false
  modalEdit: string = 'projectModalGravellogEdit'
  modalRemove: string = 'projectModalGravellogRemove'

  //userId or projectId should be set by the parent component
  userId: number = null
  projectId: number = null


  edit(gravelLog: GravelLogDataTableViewModel): void {
    this.ngxSmartModalService.resetModalData(this.modalEdit)
    let data = {
      id: Number(gravelLog.id),
      userId: null,
      projectId: null
    }
    this.ngxSmartModalService.setModalData(data, this.modalEdit);
    this.ngxSmartModalService.open(this.modalEdit);
  }

  remove(gravelLog: GravelLogDataTableViewModel): void {
    this.ngxSmartModalService.setModalData(Number(gravelLog.id), this.modalRemove);
    this.ngxSmartModalService.open(this.modalRemove);
  }

  ngOnInit() {
    this.initDatatable()
  }

  ngAfterViewInit() {
    this.moveCustomFilters()
    this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    this.reloadDatatableOnModalCloseSuccess(this.modalRemove)

  }

  ngAfterContentInit() {
    this.showHistory = localStorage.getItem('DataTables_showHistory_gravelLogs') == "true"
  }

  initDatatable(): void {
    let self = this
    let lastDraw = 0
    this.gravelLogDtOptions = {
      ...this.dataTableOptions.defaults, ...{
        ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
          let request = JSON.stringify(dataTablesParameters)
            + (self.userId ? "&userId=" + self.userId : "")
            + (self.projectId ? "&projectId=" + self.projectId : "")
            + "&showHistory=" + self.showHistory
          self.http.get<DataTablesResponse>(`api/GravelLog/Datatable?request=${request}`).subscribe(response => {
            if (response.draw < lastDraw) {
              return;
            }
            lastDraw = response.draw
            self.gravelLogs = response.data;
            let callbackResponse: DataTablesResponse = {
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              data: [],
              draw: response.draw
            }
            callback(callbackResponse);
          });
        },
      },
      columns: [
        { data: 'projectName' },
        { data: 'material' },
        { data: 'distance' },
        { data: 'supplier' },
        { data: 'weight' },
        { data: 'rangering' },
        { data: 'trailer' },
        { data: 'note' },
        { data: 'user' },
        { data: 'date' }
      ]
    }
  }

  moveCustomFilters(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
        let datatableWrapper = (dtInstance.table('table').container())
        let dtId = $(datatableWrapper).attr('id')
        var closestFilter = $('#' + dtId).siblings('.dt-filter')
        $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
      })
    )
  }

  reloadDatatableOnModalCloseSuccess(modalName: string) {
    this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
      let data = modal.getData()
      if (data && data.success === true) {
        this.reloadDatatable()
      }
    });
  }

  reloadDatatable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    localStorage.setItem('DataTables_showHistory_gravelLogs', this.showHistory.toString())
  }

}
