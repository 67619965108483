import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';
import { SnowAreaReportViewModel } from '@app/models/SnowAreaReportViewModel';
import { ReportService } from '@app/services/report.service';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-snowlog-modal-report',
  templateUrl: './snowlog-modal-report.component.html',
  styleUrls: ['./snowlog-modal-report.component.css']
})
export class SnowlogModalReportComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: SnowAreaReportViewModel = new SnowAreaReportViewModel()

  print() {
    this.reportService.downloadPdf('api/report/snowArea', this.report, 'Snöröjning_' + +new Date() + '.pdf')
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.report.id = Number(id)
  }

}
