import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SnowlogDetailsComponent } from './pages/snowlog/snowlog-details/snowlog-details.component';
import { SnowlogsComponent } from './pages/snowlogs/snowlogs/snowlogs.component';
import { LayoutComponent } from './layout/layout.component';
import { ProjectDetailsComponent } from './pages/project/project-details/project-details.component';
import { ProjectsComponent } from './pages/projects/projects/projects.component';
import { CustomersComponent } from './pages/customers/customers/customers.component';
import { UsersComponent } from './pages/users/users/users.component';
import { UserDetailsComponent } from './pages/user/user-details/user-details.component';
import { MachinesComponent } from './pages/machines/machines/machines.component';
import { MachineDetailsComponent } from './pages/machine/machine-details/machine-details.component';
import { ArticlesComponent } from './pages/articles/articles/articles.component';
import { GravelPricesComponent } from './pages/gravel-prices/gravel-prices/gravel-prices.component';
import { PermissionsComponent } from './pages/permissions/permissions/permissions.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance/maintenance.component';
import { ReportsComponent } from './pages/reports/reports/reports.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: '/projects', pathMatch: 'full' },
      //{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard] },
      { path: 'project/:id', component: ProjectDetailsComponent, canActivate: [AuthenticationGuard] },
      { path: 'projects', component: ProjectsComponent, canActivate: [AuthenticationGuard] },
      { path: 'snowlog/:id', component: SnowlogDetailsComponent, canActivate: [AuthenticationGuard] },
      { path: 'snowlogs', component: SnowlogsComponent, canActivate: [AuthenticationGuard] },
      { path: 'customers', component: CustomersComponent, canActivate: [AuthenticationGuard] },
      { path: 'users', component: UsersComponent, canActivate: [AuthenticationGuard] },
      { path: 'user/:id', component: UserDetailsComponent, canActivate: [AuthenticationGuard] },
      { path: 'machines', component: MachinesComponent, canActivate: [AuthenticationGuard] },
      { path: 'machine/:id', component: MachineDetailsComponent, canActivate: [AuthenticationGuard] },
      { path: 'articles', component: ArticlesComponent, canActivate: [AuthenticationGuard] },
      { path: 'gravelprices', component: GravelPricesComponent, canActivate: [AuthenticationGuard] },
      { path: 'permissions', component: PermissionsComponent, canActivate: [AuthenticationGuard] },
      { path: 'maintenance', component: MaintenanceComponent, canActivate: [AuthenticationGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthenticationGuard] }
    ]
  },
  //no layout routes
  { path: 'login', component: LoginComponent },
  //{ path: 'register', component: RegisterComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthenticationGuard],
})
export class AppRoutingModule { }
