﻿
// Auto generated by typewriter


export class ProjectControlPostViewModel {
    
    id: number = 0;
    projectControlItemId: number = 0;
    controlItemName: string = null;
    userId: number = 0;
    date: Date = new Date(0);
    text: string = null;
    userName: string = null;
    image: string = null;
}
