import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { ArticleDataTableViewModel } from '@app/models/ArticleDataTableViewModel';
import { ArticleEditViewModel } from '@app/models/ArticleEditViewModel';
import { AuthenticationService } from '../../services/authentication.service';
import { SimpleViewModel } from '../../models/SimpleViewModel';

@Component({
  selector: 'app-articles-datatable',
  templateUrl: './articles-datatable.component.html',
  styleUrls: ['./articles-datatable.component.css']
})
export class ArticlesDatatableComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private dataTableOptions: DatatableDefaultOptions,
    private router: Router,
    private http: HttpClient,
    private auth: AuthenticationService
  ) { }

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  articlesDtOptions: DataTables.Settings = {};
  articles: ArticleDataTableViewModel[]
  modalEdit: string = 'articleEditModal';
  priceListId: number = 4;
  priceLists: SimpleViewModel[]

  edit(article: ArticleDataTableViewModel): void {
    this.http.get<ArticleEditViewModel>("/api/article/update/?id=" + article.id)
      .subscribe(article => {
        this.ngxSmartModalService.setModalData(article, this.modalEdit);
        this.ngxSmartModalService.open(this.modalEdit);
      })
  }

  ngOnInit() {
    this.initPriceLists()
    this.initDatatable()
  }

  initPriceLists() {
    this.http.get<SimpleViewModel[]>("/api/article/getPriceLists")
      .subscribe(priceLists => {
        this.priceLists = priceLists
      })
  }

  ngAfterViewInit() {
    this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    this.moveCustomFilters()
  }

  ngAfterContentInit() {
    this.priceListId = Number(localStorage.getItem('DataTables_priceListId_articles'))
    if (this.priceListId === 0) {
      this.priceListId = 4
    }
  }

  moveCustomFilters(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
        let datatableWrapper = (dtInstance.table('table').container())
        let dtId = $(datatableWrapper).attr('id')
        var closestFilter = $('#' + dtId).siblings('.dt-filter')
        $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
      })
    )
  }

  reloadDatatableOnModalCloseSuccess(modalName: string) {
    this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
      let data = modal.getData()
      if (data && data.success === true) {
        this.reloadDatatable()
      }
    });
  }

  reloadDatatable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    localStorage.setItem('DataTables_priceListId_articles', this.priceListId.toString())
  }

  initDatatable(): void {
    let self = this
    let lastDraw = 0
    this.articlesDtOptions = {
      ...this.dataTableOptions.defaults, ...{
        ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
          let request = JSON.stringify(dataTablesParameters)
            + "&priceListId=" + self.priceListId
          self.http.get<DataTablesResponse>(`api/article/Datatable?request=${request}`).subscribe(response => {
            if (response.draw < lastDraw) {
              return;
            }
            lastDraw = response.draw
            self.articles = response.data;
            let callbackResponse: DataTablesResponse = {
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              data: [],
              draw: 0
            }
            callback(callbackResponse);
          });
        },
        columns: [
          { data: 'articleNumber' },
          { data: 'name' },
          { data: 'type' },
          { data: 'unit' }
        ]
      }
    }
  }
}
