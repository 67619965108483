import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { SnowAreaDataTableViewModel } from '@app/models/SnowAreaDataTableViewModel';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { Router } from '@angular/router';

@Component({
    selector: 'app-snow-areas-overview-datatable',
    templateUrl: './snow-areas-overview-datatable.component.html',
    styleUrls: ['./snow-areas-overview-datatable.component.css']
})
export class SnowAreasOverviewDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private http: HttpClient,
        private router: Router
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    snowAreaDtOptions: DataTables.Settings = {};
    snowAreas: SnowAreaDataTableViewModel[]

    show(snowArea: SnowAreaDataTableViewModel): void {
      this.router.navigate(['/snowlog/' + snowArea.id]);
    }

    ngOnInit() {
        this.initDatatable()
    }

    ngAfterViewInit() {
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.snowAreaDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                    self.http.get<DataTablesResponse>(`api/snowArea/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.snowAreas = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'name' }
            ]
        }
    }
}
