import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-time-modal-remove',
  templateUrl: './user-time-modal-remove.component.html',
  styleUrls: ['./user-time-modal-remove.component.css']
})
export class UserTimeModalRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  id: number
  api: string = '/api/personalTime/'
  modalName: string = 'userTimeModalRemove'
  title: string = 'Ta bort tidslogg'
  text: string = 'Vill du verkligen ta bort tidsloggen?'
  successMessage: string = 'Tidsloggen har tagits bort.'
  errorMessage: string = 'Det gick inte att ta bort tidsloggen.'

  clear(): void {
    this.id = null
    this.ngxSmartModalService.get(this.modalName).removeData()
  }

  close(): void {
    this.ngxSmartModalService.close(this.modalName)
  }

  remove(): void {
    this.http.delete<any>(this.api + "delete?id=" + this.id)
      .subscribe(
        () => {
          this.toastr.success(this.successMessage);
          this.ngxSmartModalService.get(this.modalName).removeData();
          this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
          this.ngxSmartModalService.close(this.modalName);
        },
        (error) => {
          this.toastr.error(this.errorMessage);
        },
        () => {
          //complete
        }
      );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.id = modal.getData()
    });
  }
}
