import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { GravelPriceEditViewModel } from '../models/GravelPriceEditViewModel';
import { ArticleEditViewModel } from '@app/models/ArticleEditViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  private url = '/api/article/';
  constructor(private http: HttpClient) { }
  update(something: ArticleEditViewModel): Observable<any> {
    return this.http.put<ArticleEditViewModel>(this.url + 'update', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + `delete?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  updateGravelPrice(something: GravelPriceEditViewModel): Observable<any> {
    return this.http.put<GravelPriceEditViewModel>(this.url + 'updateGravelPrice', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
}
