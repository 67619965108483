import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { HttpClientModule } from '@angular/common/http';
import { NgxSmartModalModule, NgxSmartModalService } from 'ngx-smart-modal';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationGuard } from './guards/authentication.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SnowlogsComponent } from './pages/snowlogs/snowlogs/snowlogs.component';
import { SnowlogDetailsComponent } from './pages/snowlog/snowlog-details/snowlog-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { ProjectDetailsComponent } from './pages/project/project-details/project-details.component';
import { ProjectInfoComponent } from './pages/project/project-info/project-info.component';
import { ProjectDocumentsComponent } from './pages/project/project-documents/project-documents.component';
import { ProjectDetailsStatsComponent } from './pages/project/project-details-stats/project-details-stats.component';
import { ProjectsComponent } from './pages/projects/projects/projects.component';
import { ProjectStatsComponent } from './pages/projects/project-stats/project-stats.component';
import { ProjectsDatatableComponent } from './pages/projects/projects-datatable/projects-datatable.component';
import { ProjectModalEditComponent } from './pages/project/modals/project-modal-edit/project-modal-edit.component';
import { ProjectModalArchiveComponent } from './pages/project/modals/project-modal-archive/project-modal-archive.component';
import { ProjectCostDatatableComponent } from './pages/project/datatables/project-cost-datatable/project-cost-datatable.component';
import { ProjectTimeDatatableComponent } from './pages/project/datatables/project-time-datatable/project-time-datatable.component';
import { ProjectControlsDatatableComponent } from './pages/project/datatables/project-controls-datatable/project-controls-datatable.component';
import { ProjectModalReportComponent } from './pages/project/modals/project-modal-report/project-modal-report.component';
import { ProjectModalMachineTagsComponent } from './pages/project/modals/project-modal-machine-tags/project-modal-machine-tags.component';
import { SnowlogInfoComponent } from './pages/snowlog/snowlog-info/snowlog-info.component';
import { SnowlogModalReportComponent } from './pages/snowlog/snowlog-modal-report/snowlog-modal-report.component';
import { LightboxModule } from 'ngx-lightbox';
import { ProjectModalDocumentComponent } from './pages/project/modals/project-modal-document/project-modal-document.component';
import { ProjectModalDocumentUploadComponent } from './pages/project/modals/project-modal-document-upload/project-modal-document-upload.component';
import { FileUploadModule } from "ng2-file-upload";
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ProjectModalCostAddComponent } from './pages/project/modals/project-modal-cost-add/project-modal-cost-add.component';
import { ProjectModalCostRemoveComponent } from './pages/project/modals/project-modal-cost-remove/project-modal-cost-remove.component';
import { ProjectModalTimelogEditComponent } from './pages/project/modals/project-modal-timelog-edit/project-modal-timelog-edit.component';
import { ProjectModalMaterialEditComponent } from './pages/project/modals/project-modal-material-edit/project-modal-material-edit.component';
import { ProjectModalTimelogRemoveComponent } from './pages/project/modals/project-modal-timelog-remove/project-modal-timelog-remove.component';
import { ProjectModalRemoveComponent } from './pages/project/modals/project-modal-remove/project-modal-remove.component';
import { ProjectModalGravellogEditComponent } from './pages/project/modals/project-modal-gravellog-edit/project-modal-gravellog-edit.component';
import { ProjectModalGravellogRemoveComponent } from './pages/project/modals/project-modal-gravellog-remove/project-modal-gravellog-remove.component';
import { ProjectModalControlDetailsComponent } from './pages/project/modals/project-modal-control-details/project-modal-control-details.component';
import { ProjectModalControlRemoveComponent } from './pages/project/modals/project-modal-control-remove/project-modal-control-remove.component';
import { ProjectControlPostFormComponent } from './pages/project/modals/project-modal-control-details/project-control-post-form/project-control-post-form.component';
import { ProjectModalControlAddComponent } from './pages/project/modals/project-modal-control-add/project-modal-control-add.component';
import { CustomersComponent } from './pages/customers/customers/customers.component';
import { CustomersDatatableComponent } from './pages/customers/customers-datatable/customers-datatable.component';
import { CustomerModalEditComponent } from './pages/customers/modals/customer-modal-edit/customer-modal-edit.component';
import { CustomerModalRemoveComponent } from './pages/customers/modals/customer-modal-remove/customer-modal-remove.component';
import { UsersComponent } from './pages/users/users/users.component';
import { UsersDatatableComponent } from './pages/users/users-datatable/users-datatable.component';
import { UserModalEditComponent } from './pages/users/modals/user-modal-edit/user-modal-edit.component';
import { UserModalRemoveComponent } from './pages/users/modals/user-modal-remove/user-modal-remove.component';
import { UserDetailsComponent } from './pages/user/user-details/user-details.component';
import { UserInfoComponent } from './pages/user/user-info/user-info.component';
import { UserStatsComponent } from './pages/user/user-stats/user-stats.component';
import { UserTimeComponent } from './pages/user/user-time/user-time.component';
import { UserProjectTimeComponent } from './pages/user/user-project-time/user-project-time.component';
import { UserTravelLogsComponent } from './pages/user/user-travel-logs/user-travel-logs.component';
import { UserSnowLogsComponent } from './pages/user/user-snow-logs/user-snow-logs.component';
import { UserGravelLogsComponent } from './pages/user/user-gravel-logs/user-gravel-logs.component';
import { UserTimeDatatableComponent } from './pages/user/datatables/user-time-datatable/user-time-datatable.component';
import { UserProjectTimeDatatableComponent } from './pages/user/datatables/user-project-time-datatable/user-project-time-datatable.component';
import { UserTravelLogsDatatableComponent } from './pages/user/datatables/user-travel-logs-datatable/user-travel-logs-datatable.component';
import { UserTimeModalRemoveComponent } from './pages/user/modals/user-time-modal-remove/user-time-modal-remove.component';
import { UserTimeModalEditComponent } from './pages/user/modals/user-time-modal-edit/user-time-modal-edit.component';
import { UserTravellogModalEditComponent } from './pages/user/modals/user-travellog-modal-edit/user-travellog-modal-edit.component';
import { UserTravellogModalRemoveComponent } from './pages/user/modals/user-travellog-modal-remove/user-travellog-modal-remove.component';
import { DataTablesModule } from 'angular-datatables';
import { HttpModule } from '@angular/http';
import { DatatableDefaultOptions } from './DatatableDefaultOptions';
import { GravelLogsDatatableComponent } from './datatables/gravel-logs-datatable/gravel-logs-datatable.component';
import { ControlsDatatableComponent } from './datatables/controls-datatable/controls-datatable.component';
import { SnowLogsDatatableComponent } from './datatables/snow-logs-datatable/snow-logs-datatable.component';
import { SnowLogEditModalComponent } from './modals/snow-log-edit-modal/snow-log-edit-modal.component';
import { SnowLogRemoveModalComponent } from './modals/snow-log-remove-modal/snow-log-remove-modal.component';
import { MachinesDatatableComponent } from './pages/machines/machines-datatable/machines-datatable.component';
import { MachinesComponent } from './pages/machines/machines/machines.component';
import { MachineModalEditComponent } from './pages/machines/machine-modal-edit/machine-modal-edit.component';
import { MachineModalRemoveComponent } from './pages/machines/machine-modal-remove/machine-modal-remove.component';
import { MachineDetailsComponent } from './pages/machine/machine-details/machine-details.component';
import { MachineInfoComponent } from './pages/machine/machine-info/machine-info.component';
import { MachineDetailsStatsComponent } from './pages/machine/machine-details-stats/machine-details-stats.component';
import { ServiceLogsDatatableComponent } from './datatables/service-logs-datatable/service-logs-datatable.component';
import { RepairLogsDatatableComponent } from './datatables/repair-logs-datatable/repair-logs-datatable.component';
import { ServiceLogEditModalComponent } from './modals/service-log-edit-modal/service-log-edit-modal.component';
import { ServiceLogRemoveModalComponent } from './modals/service-log-remove-modal/service-log-remove-modal.component';
import { RepairLogEditModalComponent } from './modals/repair-log-edit-modal/repair-log-edit-modal.component';
import { RepairLogRemoveModalComponent } from './modals/repair-log-remove-modal/repair-log-remove-modal.component';
import { ProjectChartComponent } from './pages/project/project-chart/project-chart.component';
import { ChartsModule } from 'ng2-charts';
import { ArticlesComponent } from './pages/articles/articles/articles.component';
import { ArticlesDatatableComponent } from './datatables/articles-datatable/articles-datatable.component';
import { GravelPricesDatatableComponent } from './datatables/gravel-prices-datatable/gravel-prices-datatable.component';
import { GravelPricesComponent } from './pages/gravel-prices/gravel-prices/gravel-prices.component';
import { GravelPriceEditModalComponent } from './modals/gravel-price-edit-modal/gravel-price-edit-modal.component';
import { PermissionsComponent } from './pages/permissions/permissions/permissions.component';
import { PermissionsDatatableComponent } from './datatables/permissions-datatable/permissions-datatable.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MaintenanceComponent } from './pages/maintenance/maintenance/maintenance.component';
import { TimeTypesDatatableComponent } from './datatables/time-types-datatable/time-types-datatable.component';
import { TimeTypeEditModalComponent } from './modals/time-type-edit-modal/time-type-edit-modal.component';
import { TimeTypeRemoveModalComponent } from './modals/time-type-remove-modal/time-type-remove-modal.component';
import { SnowAreasDatatableComponent } from './datatables/snow-areas-datatable/snow-areas-datatable.component';
import { SnowAreasOverviewDatatableComponent } from './datatables/snow-areas-overview-datatable/snow-areas-overview-datatable.component';
import { SnowAreaEditModalComponent } from './modals/snow-area-edit-modal/snow-area-edit-modal.component';
import { SnowAreaRemoveModalComponent } from './modals/snow-area-remove-modal/snow-area-remove-modal.component';
import { ModalTemplateComponent } from './modals/modal-template/modal-template.component';
import { ControlEditModalComponent } from './modals/control-edit-modal/control-edit-modal.component';
import { ControlRemoveModalComponent } from './modals/control-remove-modal/control-remove-modal.component';
import { ReportsComponent } from './pages/reports/reports/reports.component';
import { UserMachineReportComponent } from './pages/reports/user-machine-report/user-machine-report.component';
import { MachineReportComponent } from './pages/reports/machine-report/machine-report.component';
import { ProjectMonthReportComponent } from './pages/reports/project-month-report/project-month-report.component';
import { GravelReportComponent } from './pages/reports/gravel-report/gravel-report.component';
import { SalaryReportComponent } from './pages/reports/salary-report/salary-report.component';
import { UserReportComponent } from './pages/reports/user-report/user-report.component';
import { MaterialReportComponent } from './pages/reports/material-report/material-report.component';
import { SnowReportComponent } from './pages/reports/snow-report/snow-report.component';
import { UserWagesModalComponent } from './pages/user/modals/user-wages-modal/user-wages-modal.component';
import { ArticleEditModalComponent } from './pages/articles/article-edit-modal/article-edit-modal.component';
import { LocalDatePipe } from './pipes/local-date/local-date.pipe';
import { LocalNumberPipe } from './pipes/local-number/local-number.pipe';
import { ProjectCostDocumentEditModalComponent } from './modals/project-cost-document-edit-modal/project-cost-document-edit-modal.component';
import { MessageService } from './services/message.service';
import { DatepickerDefaultOptions } from './DatepickerDefaultOptions';
import { ProjectModalCostEditComponent } from './pages/project/modals/project-modal-cost-edit/project-modal-cost-edit.component';

registerLocaleData(localeSv, 'sv');

@NgModule({
  entryComponents: [ModalTemplateComponent],
  declarations: [
    AppComponent,
    MainMenuComponent,
    ProjectsComponent,
    ProjectStatsComponent,
    ProjectsDatatableComponent,
    SnowlogsComponent,
    SnowlogDetailsComponent,
    DashboardComponent,
    ProjectModalEditComponent,
    LoginComponent,
    LayoutComponent,
    ProjectDetailsComponent,
    ProjectInfoComponent,
    ProjectDocumentsComponent,
    ProjectCostDatatableComponent,
    ProjectTimeDatatableComponent,
    ProjectControlsDatatableComponent,
    ProjectDetailsStatsComponent,
    ProjectModalArchiveComponent,
    ProjectModalReportComponent,
    ProjectModalMachineTagsComponent,
    SnowlogInfoComponent,
    SnowlogModalReportComponent,
    ProjectModalDocumentComponent,
    ProjectModalDocumentUploadComponent,
    ProjectModalCostAddComponent,
    ProjectModalCostEditComponent,
    ProjectModalCostRemoveComponent,
    ProjectModalTimelogEditComponent,
    ProjectModalMaterialEditComponent,
    ProjectModalTimelogRemoveComponent,
    ProjectModalRemoveComponent,
    ProjectModalGravellogEditComponent,
    ProjectModalGravellogRemoveComponent,
    ProjectModalControlDetailsComponent,
    ProjectModalControlRemoveComponent,
    ProjectControlPostFormComponent,
    ProjectModalControlAddComponent,
    CustomersComponent,
    CustomersDatatableComponent,
    CustomerModalEditComponent,
    CustomerModalRemoveComponent,
    UsersComponent,
    UsersDatatableComponent,
    UserModalEditComponent,
    UserModalRemoveComponent,
    UserDetailsComponent,
    UserInfoComponent,
    UserStatsComponent,
    UserTimeComponent,
    UserProjectTimeComponent,
    UserTravelLogsComponent,
    UserSnowLogsComponent,
    UserGravelLogsComponent,
    UserTimeDatatableComponent,
    UserProjectTimeDatatableComponent,
    UserTravelLogsDatatableComponent,
    GravelLogsDatatableComponent,
    UserTimeModalRemoveComponent,
    UserTimeModalEditComponent,
    UserTravellogModalEditComponent,
    UserTravellogModalRemoveComponent,
    ControlsDatatableComponent,
    SnowLogsDatatableComponent,
    SnowLogEditModalComponent,
    SnowLogRemoveModalComponent,
    MachinesDatatableComponent,
    MachinesComponent,
    MachineModalEditComponent,
    MachineModalRemoveComponent,
    MachineDetailsComponent,
    MachineInfoComponent,
    MachineDetailsStatsComponent,
    ServiceLogsDatatableComponent,
    RepairLogsDatatableComponent,
    ServiceLogEditModalComponent,
    ServiceLogRemoveModalComponent,
    RepairLogEditModalComponent,
    RepairLogRemoveModalComponent,
    ProjectChartComponent,
    ArticlesComponent,
    ArticlesDatatableComponent,
    GravelPricesDatatableComponent,
    GravelPricesComponent,
    GravelPriceEditModalComponent,
    PermissionsComponent,
    PermissionsDatatableComponent,
    MaintenanceComponent,
    TimeTypesDatatableComponent,
    TimeTypeEditModalComponent,
    TimeTypeRemoveModalComponent,
    SnowAreasDatatableComponent,
    SnowAreasOverviewDatatableComponent,
    SnowAreaEditModalComponent,
    SnowAreaRemoveModalComponent,
    ModalTemplateComponent,
    ControlEditModalComponent,
    ControlRemoveModalComponent,
    ReportsComponent,
    UserMachineReportComponent,
    MachineReportComponent,
    ProjectMonthReportComponent,
    GravelReportComponent,
    SalaryReportComponent,
    UserReportComponent,
    MaterialReportComponent,
    SnowReportComponent,
    UserWagesModalComponent,
    ArticleEditModalComponent,
    LocalDatePipe,
    LocalNumberPipe,
    ProjectCostDocumentEditModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSmartModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    LightboxModule,
    FileUploadModule,
    LoadingBarHttpClientModule,
    DataTablesModule,
    HttpModule,
    ChartsModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    LocalDatePipe,
    LocalNumberPipe
  ],
  providers: [
    AuthenticationGuard,
    NgxSmartModalService,
    DatatableDefaultOptions,
    DatepickerDefaultOptions,
    MessageService,
    { provide: LOCALE_ID, useFactory: () => 'sv-SE' }
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }
