import { Component, OnInit } from '@angular/core';
import { TimelogService } from '@app/services/timelog.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserStatsViewModel } from '@app/models/UserStatsViewModel';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.css']
})
export class UserStatsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  stats: UserStatsViewModel
  userId: number = null

  ngOnInit() {
    this.userId = this.route.snapshot.params['id']
    this.userService.getUserStats(this.userId).subscribe((data) => {
      this.stats = data;
      this.stats.billablePercent = 0
      if (this.stats.payBasedTime != 0) {
        this.stats.billablePercent = Math.floor((this.stats.projectTime / this.stats.payBasedTime) * 100)
      } else {
        this.stats.billablePercent = Math.floor(this.stats.payBasedTime * 100)
      }
    }, (error) => {
      this.toastr.error('Det gick inte att hämta statistik för användaren.');
    }, () => {
      //complete
    });
  }

}
