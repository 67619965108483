import { Component, OnInit } from '@angular/core';
import { ProjectMonthReportViewModel } from '@app/models/ProjectMonthReportViewModel';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-project-month-report',
  templateUrl: './project-month-report.component.html',
  styleUrls: ['./project-month-report.component.css']
})
export class ProjectMonthReportComponent implements OnInit {

  constructor(
  private datePickerDefaultOptions: DatepickerDefaultOptions
) { }

  report: ProjectMonthReportViewModel = new ProjectMonthReportViewModel()
  dropdownSettings = {}
  datepickerConfig = this.datePickerDefaultOptions.defaults

  isPrinting: boolean = false
  isPrintingExcel: boolean = false

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };

    this.report.projectTime = true
    this.report.projectTimeCost = true
    this.report.projectCost = true
    this.report.material = true
    this.report.materialCost = true
    this.report.gravel = true
    this.report.gravelCost = true
    this.report.priceListId = 4
  }

  ngAfterViewInit() {

  }



  print(): void {
    this.isPrinting = true
    this.downloadFile('api/report/projectMonth', this.report, 'application/pdf', '.pdf')
  }

  printExcel(): void {
    this.isPrintingExcel = true
    this.downloadFile('api/report/projectMonthExcel', this.report, 'application/octet-stream', '.xlsx')
  }

  downloadFile(url, data, type, extension): void {
    var self = this
    var xhr = new XMLHttpRequest();
    xhr.open('post', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.responseType = 'blob';
    xhr.send(JSON.stringify(data));

    xhr.onloadend = function (data: any) {
      console.log(data)
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: type });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'Månadsavslut_' + new Date().toLocaleDateString('sv-SE') + extension;
        a.click();
        window.URL.revokeObjectURL(url);
        self.isPrinting = false
        self.isPrintingExcel = false
      } else {
        // error
        self.isPrinting = false
        self.isPrintingExcel = false
      }
    };
  }
}
