
// Auto generated by typewriter


export class ProjectInfoViewModel {
    
    id: number = 0;
    title: string = null;
    description: string = null;
    littra: string = null;
    projectNumber: string = null;
    customerName: string = null;
    customerNumber: string = null;
    address: string = null;
    zipCode: string = null;
    city: string = null;
    vismaOrderNumber: string = null;
    isReadyForInvoice: boolean = false;
}
