﻿
// Auto generated by typewriter


export class RepairLogEditViewModel {
    
    id: number = null;
    employeeId: number = null;
    projectId: number = null;
    machineId: number = null;
    projectTitle: string = null;
    mileage: number = 0;
    time: number = 0;
    date: Date = new Date(0);
    note: string = null;
}
