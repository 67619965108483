
// Auto generated by typewriter


export class MachineInfoViewModel {
    
    id: number = 0;
    name: string = null;
    articleNumber: string = null;
    time: number = 0;
    type: string = null;
}
