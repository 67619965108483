import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '@app/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectCostViewModel } from '@app/models/ProjectCostViewModel';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';
import { MessageService } from '../../../../services/message.service';
import { Messages } from '../../../../models/enums/Messages';


@Component({
  selector: 'app-project-modal-cost-add',
  templateUrl: './project-modal-cost-add.component.html',
  styleUrls: ['./project-modal-cost-add.component.css']
})
export class ProjectModalCostAddComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private datePickerDefaultOptions: DatepickerDefaultOptions,
    private messageService: MessageService
  ) { }

  projectCosts: ProjectCostViewModel[] = []
  date: Date
  datepickerConfig = this.datePickerDefaultOptions.defaults

  projectCostForm = this.formBuilder.group({
    projectId: ['', [Validators.required]],
    name: ['', [Validators.required]],
    price: ['', [Validators.required]],
    date: ['']
  });
  get form() { return this.projectCostForm.controls; }
  resetForm() {
    const defaultValues = {
      projectId: null,
      name: null,
      price: null,
      date: null
    };
    this.projectCostForm.reset(defaultValues);
  }

  clear(): void {
    this.ngxSmartModalService.get('projectModalCostAdd').removeData();
    this.resetForm();
  }

  add(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectCostForm.patchValue({ projectId: id })

    if (!this.projectCostForm.valid) {
      return
    }

    this.projectCosts.push(this.projectCostForm.value);
    this.projectCostForm.reset();
  }

  remove(index: number): void {
    this.projectCosts.splice(index, 1)
  }

  save(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectCostForm.patchValue({projectId: id })
    this.projectService.saveProjectCosts(this.projectCosts).subscribe(
      () => {
        let message = 'Kostnadspost skapad!';
        if (this.projectCosts.length > 1) {
          message = 'Kostnadsposter skapade!'
        }
        this.messageService.sendMessage(Messages.ReloadProjectStats)
        this.toastr.success(message);
        this.ngxSmartModalService.get('projectModalCostAdd').removeData();
        this.ngxSmartModalService.setModalData({ success: true }, 'projectModalCostAdd');
        this.projectCostForm.reset();
        this.projectCosts = []
        this.ngxSmartModalService.close('projectModalCostAdd');
      },
      (error) => {
        this.toastr.error('Det gick inte att skapa kostnadspost.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
      this.save()
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectModalCostAdd').onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var data = modal.getData()
    });
  }
}
