import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '@app/services/project.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectCostViewModel } from '@app/models/ProjectCostViewModel';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';
import { MessageService } from '../../../../services/message.service';
import { Messages } from '../../../../models/enums/Messages';

@Component({
  selector: 'app-project-modal-cost-edit',
  templateUrl: './project-modal-cost-edit.component.html',
  styleUrls: ['./project-modal-cost-edit.component.css']
})
export class ProjectModalCostEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private datePickerDefaultOptions: DatepickerDefaultOptions,
    private messageService: MessageService
  ) { }

  projectCost: ProjectCostViewModel
  date: Date
  datepickerConfig = this.datePickerDefaultOptions.defaults
  modalName: string = 'projectModalCostEdit';

  projectCostForm = this.formBuilder.group({
    id: ['', [Validators.required]],
    projectId: ['', [Validators.required]],
    name: ['', [Validators.required]],
    price: ['', [Validators.required]],
    date: [''],
    invoiced: ['', [Validators.required]],
  });

  defaultValues = {
    id: null,
    projectId: null,
    name: null,
    price: null,
    date: null
  };
  get form() { return this.projectCostForm.controls; }
  resetForm() {

    this.projectCostForm.reset(this.defaultValues);
  }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm();
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.projectService.updateProjectCost(this.projectCostForm.value).subscribe(
      () => {
        this.toastr.success('Kostnaden har sparats.')
        this.messageService.sendMessage(Messages.ReloadProjectStats)
        this.closeModalWithSuccess()
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.toastr.error('Det gick inte att spara kostnaden.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
      this.save()
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var data = modal.getData()
      this.projectCostForm.setValue(data)
      //convert UTC to local time
      this.date = new Date(data.date)
      this.projectCostForm.patchValue({ date: new Date(data.date) })
    });
  }
}
