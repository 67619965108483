import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-user-travellog-modal-remove',
  templateUrl: './user-travellog-modal-remove.component.html',
  styleUrls: ['./user-travellog-modal-remove.component.css']
})
export class UserTravellogModalRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private http: HttpClient
  ) { }

  modalName: string = 'userTravellogModalRemove'
  travelLogId: number = null

  clear(): void {
    this.travelLogId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    this.http.delete<any>("/api/travelLog/delete?id=" + this.travelLogId)
      .subscribe(
        () => {
          this.toastr.success('Resan har tagits bort.');
          this.ngxSmartModalService.get(this.modalName).removeData();
          this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
          this.ngxSmartModalService.close(this.modalName);
        },
        (error) => {
          this.toastr.error('Det gick inte att ta bort resan.');
        },
        () => {
          //complete
        }
      );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.travelLogId = modal.getData()
    });
  }
}
