import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ProjectTimeLogDataTableViewModel } from '../../../../models/ProjectTimeLogDataTableViewModel';
import { DataTableDirective } from 'angular-datatables';
import { DatatableDefaultOptions } from '../../../../DatatableDefaultOptions';
import { DataTablesResponse } from '../../../../models/DataTablesResponse';
import { DataTablesRequest } from '../../../../models/DataTablesRequest';
import { AuthenticationService } from '../../../../services/authentication.service';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-time-datatable',
  templateUrl: './project-time-datatable.component.html',
  styleUrls: ['./project-time-datatable.component.css']
})
export class ProjectTimeDatatableComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private dataTableOptions: DatatableDefaultOptions,
    private auth: AuthenticationService,
    private datePickerDefaultOptions: DatepickerDefaultOptions,
    private router: Router
  ) { }

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  projectTimeDtOptions: DataTables.Settings = {};
  timeLogs: ProjectTimeLogDataTableViewModel[]
  datepickerConfig = this.datePickerDefaultOptions.defaults

  ata: boolean = false
  showHistory: boolean = false
  filterFromDate: Date
  filterToDate: Date
  showDateFilters: boolean = false
  showProject: boolean = true;

  materialModal: string = 'projectModalMaterialEdit'
  modalEdit: string = 'projectModalTimelogEdit'
  modalRemove: string = 'projectModalTimelogRemove'

  //this component can be used for showing timelogs per user or per project
  projectId: number = null
  userId: number = null

  toggleDatePickers(): void {
    this.showDateFilters = !this.showDateFilters
  }

  dateChanged() {
    this.reloadDatatable()
  }

  material(timeLog: ProjectTimeLogDataTableViewModel): void {
    this.ngxSmartModalService.resetModalData(this.materialModal)
    this.ngxSmartModalService.setModalData(timeLog.id, this.materialModal);
    this.ngxSmartModalService.open(this.materialModal);
  }

  edit(timeLog: ProjectTimeLogDataTableViewModel): void {
    this.http.get<any>("/api/timelog/update/" + timeLog.id)
      .subscribe(data => {
        this.ngxSmartModalService.resetModalData(this.modalEdit)
        this.ngxSmartModalService.setModalData(data, this.modalEdit);
        this.ngxSmartModalService.open(this.modalEdit);
      });
  }

  remove(timeLog: ProjectTimeLogDataTableViewModel): void {
    this.ngxSmartModalService.resetModalData(this.modalRemove)
    this.ngxSmartModalService.setModalData(timeLog.id, this.modalRemove);
    this.ngxSmartModalService.open(this.modalRemove);
  }

  ngOnInit() {
    this.showProject = !this.router.url.startsWith("/project/")
    this.initDatatable()
  }

  ngAfterViewInit() {
    this.moveCustomFilters()
    this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    this.reloadDatatableOnModalCloseSuccess(this.modalRemove)

  }

  ngAfterContentInit() {
    this.showHistory = localStorage.getItem('DataTables_showHistory_projectTime') == "true"
    this.ata = localStorage.getItem('DataTables_showHistory_projectTimeAta') == "true"
  }

  receiveMessage(message) {
    if (message === 'projectTimeTable:reload') {
      this.reloadDatatable()
    }
  }

  initDatatable(): void {
    let self = this
    let lastDraw = 0

    let columns = [
      { data: 'user' },
      (this.showProject ? { data: 'project' } : null),
      { data: 'machine' },
      { data: 'workType' },
      { data: 'hours' },
      { data: 'date' },
      { data: 'note' },
      { data: 'internalNote' }
    ].filter(x => x !== null);

    this.projectTimeDtOptions = {
      ...this.dataTableOptions.defaults, ...{
        ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
          let request = JSON.stringify(dataTablesParameters)
            + (self.userId ? "&userId=" + self.userId : "")
            + (self.projectId ? "&projectId=" + self.projectId : "")
            + "&showHistory=" + self.showHistory
            + (self.filterFromDate ? "&fromDate=" + self.filterFromDate.toJSON() : "")
            + (self.filterToDate ? "&toDate=" + self.filterToDate.toJSON() : "")

          if (self.projectId) {
            request += "&ata=" + self.ata
          }

          self.http.get<DataTablesResponse>(`api/TimeLog/Datatable?request=${request}`).subscribe(response => {
            if (response.draw < lastDraw) {
              return;
            }
            lastDraw = response.draw
            self.timeLogs = response.data;
            let callbackResponse: DataTablesResponse = {
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              data: [],
              draw: response.draw
            }
            callback(callbackResponse);
          });
        },
        columns: columns,
        order: [[this.showProject ? 5 : 4, 'asc']]
      }
    }
  }

  moveCustomFilters(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
        let datatableWrapper = (dtInstance.table('table').container())
        let dtId = $(datatableWrapper).attr('id')
        var closestFilter = $('#' + dtId).siblings('.dt-filter')
        $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
      })
    )
  }

  reloadDatatableOnModalCloseSuccess(modalName: string) {
    this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
      let data = modal.getData()
      if (data && data.success === true) {
        this.reloadDatatable()
      }
    });
  }

  reloadDatatable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    localStorage.setItem('DataTables_showHistory_projectTime', this.showHistory.toString())
    localStorage.setItem('DataTables_showHistory_projectTimeAta', this.ata.toString())
  }

}
