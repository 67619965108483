import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent, NgxSmartModalModule } from 'ngx-smart-modal';
//import { ActivatedRoute } from '@angular/router';
//import * as $ from 'jquery';
//import 'datatables.net'
import { AuthenticationService } from '../../../../services/authentication.service';
import { DatatableDefaultOptions } from '../../../../DatatableDefaultOptions';
import { DataTableDirective } from 'angular-datatables';
import { ProjectCostDataTableViewModel } from '../../../../models/ProjectCostDataTableViewModel';
import { DataTablesRequest } from '../../../../models/DataTablesRequest';
import { DataTablesResponse } from '../../../../models/DataTablesResponse';

@Component({
  selector: 'app-project-cost-datatable',
  templateUrl: './project-cost-datatable.component.html',
  styleUrls: ['./project-cost-datatable.component.css']
})
export class ProjectCostDatatableComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private auth: AuthenticationService,
    private dataTableOptions: DatatableDefaultOptions
  ) {
  }

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  projectCostDtOptions: DataTables.Settings = {};
  projectCosts: ProjectCostDataTableViewModel[]
  showHistory: boolean = false
  modalRemove: string = 'projectModalCostRemove'
  modalEdit: string = 'projectModalCostEdit'
  modalDocument: string = 'projectCostDocumentEditModal'
  projectId: number = null //set from parent component

  totalCost() {
    if (this.projectCosts) {
      return this.projectCosts.reduce((a, b) => +a + +b.price, 0)
    }
    return 0
  }

  ngOnInit() {
    this.initDatatable();
  }

  ngAfterViewInit() {
    this.moveCustomFilters()
    this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    this.reloadDatatableOnModalCloseSuccess(this.modalDocument)

  }

  ngAfterContentInit() {
    this.showHistory = localStorage.getItem('DataTables_showHistory_projectCosts') == "true"
  }

  moveCustomFilters(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
        let datatableWrapper = (dtInstance.table('table').container())
        let dtId = $(datatableWrapper).attr('id')
        var closestFilter = $('#' + dtId).siblings('.dt-filter')
        $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
      })
    )
  }

  reloadDatatableOnModalCloseSuccess(modalName: string) {
    this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
      let data = modal.getData()
      if (data && data.success === true) {
        this.reloadDatatable()
      }
    });
  }

  reloadDatatable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    localStorage.setItem('DataTables_showHistory_projectCosts', this.showHistory.toString())
  }

  edit(cost: ProjectCostDataTableViewModel): void {
    this.http.get<any>("/api/projectcost/update/" + cost.id)
      .subscribe(data => {
        this.ngxSmartModalService.resetModalData(this.modalEdit)
        this.ngxSmartModalService.setModalData(data, this.modalEdit);
        this.ngxSmartModalService.open(this.modalEdit);
      });
  }

  remove(cost: ProjectCostDataTableViewModel): void {
    this.ngxSmartModalService.setModalData({ projectCostId: cost.id }, this.modalRemove);
    this.ngxSmartModalService.open(this.modalRemove);
  }

  editDocument(cost: ProjectCostDataTableViewModel): void {
    this.ngxSmartModalService.setModalData({ projectCost: cost, projectId: this.projectId }, this.modalDocument);
    this.ngxSmartModalService.open(this.modalDocument);
  }

  initDatatable(): void {
    let self = this
    let lastDraw = 0
    this.projectCostDtOptions = {
      ...this.dataTableOptions.defaults, ...{
        ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
          let request = JSON.stringify(dataTablesParameters)
            + (self.projectId ? "&projectId=" + self.projectId : "")
            + "&showHistory=" + self.showHistory
          self.http.get<DataTablesResponse>(`api/ProjectCost/Datatable?request=${request}`).subscribe(response => {
            if (response.draw < lastDraw) {
              return;
            }
            lastDraw = response.draw
            self.projectCosts = response.data;
            let callbackResponse: DataTablesResponse = {
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              data: [],
              draw: response.draw
            }
            callback(callbackResponse);
          });
        },
      },
      columns: [
        { data: 'name' },
        { data: 'price' },
        { data: 'date' },
        { data: null },
        { data: null },
        { data: null }
      ]
    }
  }
}
