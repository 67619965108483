import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-project-modal-machine-tags',
  templateUrl: './project-modal-machine-tags.component.html',
  styleUrls: ['./project-modal-machine-tags.component.css']
})
export class ProjectModalMachineTagsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,) { }

  projectMachineTagsForm = this.formBuilder.group({
    projectId: ['',
      [
        Validators.required
      ]
    ],
    machineId: [''],
    hours: [''],
  });
  
  get form() { return this.projectMachineTagsForm.controls; }

  machines: SimpleViewModel[] = [];
  projectMachineTags: ProjectMachineTags = new ProjectMachineTags();

  addMachineTag() {
    var machineTag = new MachineTag();
    machineTag.machineId = this.projectMachineTagsForm.controls['machineId'].value;
    machineTag.hours = this.projectMachineTagsForm.controls['hours'].value;
    //TODO
    //testing
    machineTag.machineId = 1;
    machineTag.hours = 2;
    machineTag.machineName = 'test'; //TODO, get name from machines array
    this.projectMachineTags.machineTags.push(machineTag);
  }

  submit() {
    //save them
  }

  onSubmit() {

  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectMachineTags.projectId = Number(id);
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectModalMachineTags').onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.http.get<SimpleViewModel[]>("/api/machine/getAll")
        .subscribe(data => {
          this.machines = data;
        });
    });
  }

}

export class MachineTag {
  machineId: number;
  machineName: string;
  hours: number;
}

export class ProjectMachineTags {
  projectId: number;
  machineTags: MachineTag[] = [];
}

