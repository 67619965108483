import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProjectService } from '@app/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup } from "@angular/forms";
import { FileUploader, FileItem } from "ng2-file-upload";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { Messages } from '../../../../models/enums/Messages';

@Component({
  selector: 'app-project-modal-document-upload',
  templateUrl: './project-modal-document-upload.component.html',
  styleUrls: ['./project-modal-document-upload.component.css']
})
export class ProjectModalDocumentUploadComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) { }

  @Output() messageEvent = new EventEmitter<string>();
  uploadForm: FormGroup;

  public uploader: FileUploader = new FileUploader({
    isHTML5: true
  });

  clear(): void {
    this.ngxSmartModalService.get('projectModalDocumentUpload').removeData()
    this.uploader.clearQueue()
  }

  getProgressBar(item: FileItem): any {
    var bg = `linear-gradient(to right,#b1f18a ${item.progress}%,white ${item.progress}%)`;
    let styles = {
      'background-image': bg
    };
    return styles;
  }

  uploadSubmit() {
    let projectId = this.route.snapshot.paramMap.get('id');
    let queueLength = this.uploader.queue.length
    for (let i = 0; i < queueLength; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (fileItem.size > 209715200) {
        this.toastr.error("Varje fil får max vara 200 MB stor.");
        continue;
      }
      let data = new FormData();
      data.append('file', fileItem);
      data.append('fileSeq', 'seq' + i);
      data.append('projectId', projectId);
      this.uploadFile(data, i)
    }
  }

  uploadFile(data: FormData, queueIndex: number): void {
    const req = new HttpRequest('POST', 'api/project/uploadDocument', data, {
      reportProgress: true,
    });

    this.http.request(req).subscribe(event => {
      let queueItem: FileItem = this.uploader.queue[queueIndex]

      if (!queueItem) {
        return
      }

      if (event.type === HttpEventType.UploadProgress) {
        const percentDone = Math.round(100 * event.loaded / event.total);
        queueItem.isUploading = true
        queueItem.progress = percentDone
      }
      else if (event instanceof HttpResponse) {
        queueItem.isUploading = false
        if (event.status == 200) {
          this.toastr.success('Fil uppladdad!');
          this.messageEvent.emit(Messages.ReloadDocuments)
        }
      }
    },
      (error) => {
        this.toastr.error('Det gick inte att ladda upp!');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
    this.uploadForm = this.fb.group({
      document: [null, null]
    });
  }

}
