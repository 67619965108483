import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MachineService } from '@app/services/machine.service';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-machine-modal-edit',
  templateUrl: './machine-modal-edit.component.html',
  styleUrls: ['./machine-modal-edit.component.css']
})
export class MachineModalEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private machineService: MachineService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {
    this.formGroup = this.formBuilder.group({
      'id': [''],
      'articleNumber': [''],
      'name': ['', [Validators.required]],
      'type': [''],
      'deleted': ['']
    })
  }

  isNew: boolean = false
  modalName: string = 'machineModalEdit';
  formGroup: FormGroup
  get form() { return this.formGroup.controls; }

  resetForm() {
    const defaultValues = {
      id: 0,
      articleNumber: '',
      name: '',
      type: '',
      deleted: false
    };
    this.formGroup.reset(defaultValues);
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
    
  }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm()
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.machineService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Maskinen har sparats.')
        this.closeModalWithSuccess()
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.toastr.error('Det gick inte att spara maskinen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.machineService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Maskinen har ändrats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att ändra maskinen.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit(): void {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }


  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      let machine = modal.getData();
      if (!machine) {
        this.isNew = true
        this.resetForm()
      } else {
        this.isNew = false
        this.formGroup.setValue(machine)
      }
    });
  }

}
