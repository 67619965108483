import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProjectInfoViewModel } from '@app/models/ProjectInfoViewModel';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService } from '../../../services/authentication.service';
import { ProjectService } from '../../../services/project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.css']
})
export class ProjectInfoComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) { }

  projectInfo = new ProjectInfoViewModel;

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.http.get<ProjectInfoViewModel>("/api/project/info/" + id)
      .subscribe(data => {
        this.projectInfo = data;
      });
  }

  readyForInvoice(isReadyForInvoice: boolean) {

    if (isReadyForInvoice) {
      this.projectService.setReadyToInvoice(this.projectInfo.id).subscribe(
        () => {
          this.projectInfo.isReadyForInvoice = true;
          this.toastr.success('Projektets status har ändrats.');
        },
        (error) => {
          this.toastr.error('Det gick inte att ändra status på projektet.');
        },
        () => {
          //complete
        }
      );
    } else {
      this.projectService.resetReadyToInvoice(this.projectInfo.id).subscribe(
        (newStatus) => {
          this.projectInfo.isReadyForInvoice = false;
          this.toastr.success('Projektets status har ändrats.');
        },
        (error) => {
          this.toastr.error('Det gick inte att ändra status på projektet.');
        },
        () => {
          //complete
        }
      );
    }
  }

}
