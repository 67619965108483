import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-details-stats',
  templateUrl: './machine-details-stats.component.html',
  styleUrls: ['./machine-details-stats.component.css']
})
export class MachineDetailsStatsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
