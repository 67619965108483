import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { TravellogService } from '@app/services/travellog.service';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-user-travellog-modal-edit',
  templateUrl: './user-travellog-modal-edit.component.html',
  styleUrls: ['./user-travellog-modal-edit.component.css']
})
export class UserTravellogModalEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private travellogService: TravellogService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) {
    this.formGroup = this.formBuilder.group({
      'id': [''],
      'userId': [''],
      'from': [''],
      'to': [''],
      'km': ['', [Validators.required]],
      'mileage': [''],
      'regNumber': [''],
      'date': [''],
    })
  }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  date: Date
  isNew: boolean = false
  modalName: string = 'userTravellogModalEdit';
  formGroup: FormGroup
  get form() { return this.formGroup.controls; }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.formGroup.reset()
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    let userId = Number(this.route.snapshot.paramMap.get('id'))
    this.formGroup.patchValue({ userId: userId })
    this.travellogService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Resan har sparats.')
        this.closeModalWithSuccess()
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.toastr.error('Det gick inte att spara resan.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.travellogService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Resan har ändrats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att ändra resan.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      let data = modal.getData();
      if (!data) {
        this.isNew = true
        this.formGroup.reset()
      } else {
        this.isNew = false
        this.formGroup.setValue(data)

        //UTC to local datetime
        this.date = new Date(data.date)
        this.formGroup.patchValue({ date: new Date(data.date)})
      }
    });
  }

}
