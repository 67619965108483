import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { TimeTypeService } from '@app/services/timeType.service';

@Component({
  selector: 'app-time-type-remove-modal',
  templateUrl: './time-type-remove-modal.component.html',
  styleUrls: ['./time-type-remove-modal.component.css']
})
export class TimeTypeRemoveModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private timeTypeService: TimeTypeService
  ) { }

  timeTypeId: number
  modalName: string = 'timeTypeRemoveModal'

  clear(): void {
    this.timeTypeId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.timeTypeId) {
      return;
    }

    this.timeTypeService.delete(this.timeTypeId).subscribe(
      () => {
        this.toastr.success('Tidstypen har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort tidstypen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.timeTypeId = modal.getData()
    });
  }

}
