import { Component, OnInit } from '@angular/core';
import { ReportService } from '@app/services/report.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { UserWagesViewModel } from '@app/models/UserWagesViewModel';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-user-wages-modal',
  templateUrl: './user-wages-modal.component.html',
  styleUrls: ['./user-wages-modal.component.css']
})
export class UserWagesModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: UserWagesViewModel = new UserWagesViewModel()

  print() {
    this.reportService.downloadPdf('api/report/wages', this.report, 'Löneunderlag_' + +new Date() + '.pdf')
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.report.userId = Number(id)
  }
}
