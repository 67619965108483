import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { HttpClient } from '@angular/common/http';
import { TimelogService } from '@app/services/timelog.service';
import { ActivatedRoute } from '@angular/router';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';
import { MessageService } from '../../../../services/message.service';
import { Messages } from '../../../../models/enums/Messages';
import { SimpleSoftDeleteViewModel } from '../../../../models/SimpleSoftDeleteViewModel';

@Component({
  selector: 'app-project-modal-timelog-edit',
  templateUrl: './project-modal-timelog-edit.component.html',
  styleUrls: ['./project-modal-timelog-edit.component.css']
})
export class ProjectModalTimelogEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private timelogService: TimelogService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePickerDefaultOptions: DatepickerDefaultOptions,
    private messageService: MessageService
  ) {
    this.formGroup = this.formBuilder.group({
      'id': [''],
      'userId': [''],
      'projectId': [''],
      'time': ['', [Validators.required]],
      'date': [''],
      'note': [''],
      'internalNote': [''],
      'workTypeId': [''],
      'machineId': [''],
      'billable': [''],
      'invoiced': [''],
      'createdAt': [''],
      'updatedAt': [''],
    })
  }

  defaultValues = {
    id: null,
    userId: null,
    projectId: Number(this.route.snapshot.paramMap.get('id')),
    time: null,
    date: null,
    note: null,
    internalNote: null,
    workTypeId: null,
    machineId: null,
    billable: true,
    invoiced: false,
    createdAt: new Date(),
    updatedAt: new Date()
  }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  date: Date
  users: SimpleViewModel[]
  projects: SimpleViewModel[]
  workTypes: SimpleViewModel[]
  machines: SimpleViewModel[]
  isNew: boolean = false
  modalName: string = 'projectModalTimelogEdit';
  formGroup: FormGroup
  get form() { return this.formGroup.controls; }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.formGroup.reset(this.defaultValues)
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.timelogService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Tidsloggen har sparats.')
        this.messageService.sendMessage(Messages.ReloadProjectStats)
        this.closeModalWithSuccess()
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.toastr.error('Det gick inte att spara tidsloggen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.timelogService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Tidsloggen har ändrats.');
        this.messageService.sendMessage(Messages.ReloadProjectStats)
        this.closeModalWithSuccess()
      },
      (error) => {
        //this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att uppdatera tidsloggen.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngOnInit() {

  }

  getUsers(): void {
    this.http.get<SimpleSoftDeleteViewModel[]>("/api/user/getAll")
      .subscribe(data => {
        this.users = data.map(obj => ({ ...obj, disabled: obj.isDeleted }))
      });
  }

  getProjects(): void {
    this.http.get<SimpleSoftDeleteViewModel[]>("/api/project/getAll")
      .subscribe(data => {
        this.projects = data.map(obj => ({ ...obj, disabled: obj.isDeleted }))
      });
  }

  getWorkTypes(): void {
    this.http.get<SimpleViewModel[]>("/api/workType/getAll")
      .subscribe(data => {
        this.workTypes = data;
      });
  }

  getMachines(): void {
    this.http.get<SimpleSoftDeleteViewModel[]>("/api/machine/getAll")
      .subscribe(data => {
        this.machines = data.map(obj => ({ ...obj, disabled: obj.isDeleted }))
      });
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.getUsers()
      this.getProjects()
      this.getWorkTypes()
      this.getMachines()

      let data = modal.getData();
      let formValues = this.defaultValues
      if (!data) {
        this.isNew = true
        this.formGroup.reset(formValues)
      } else {
        this.isNew = false
        this.formGroup.setValue(data)

        //convert UTC to local time
        this.date = new Date(data.date)
        this.formGroup.patchValue({ date: new Date(data.date) })
      }
    });
  }

}
