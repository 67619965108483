import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-snowlog-info',
  templateUrl: './snowlog-info.component.html',
  styleUrls: ['./snowlog-info.component.css']
})
export class SnowlogInfoComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private auth: AuthenticationService
  ) { }

  snowArea = new SimpleViewModel;

  ngOnInit() {
    let id = this.route.snapshot.params['id'];
    this.http.get<SimpleViewModel>("/api/snowArea/get/" + id)
      .subscribe(data => {
        this.snowArea = data;
      });
  }
}
