import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net'
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { CustomerEditViewModel } from '@app/models/CustomerEditViewModel';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../../services/authentication.service';
import { DatatableDefaultOptions } from '../../../DatatableDefaultOptions';
import { DataTableDirective } from 'angular-datatables';
import { CustomerDataTableViewModel } from '../../../models/CustomerDataTableViewModel';
import { DataTablesResponse } from '../../../models/DataTablesResponse';
import { DataTablesRequest } from '../../../models/DataTablesRequest';

@Component({
    selector: 'app-customers-datatable',
    templateUrl: './customers-datatable.component.html',
    styleUrls: ['./customers-datatable.component.css']
})
export class CustomersDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private http: HttpClient,
        private dataTableOptions: DatatableDefaultOptions,
        private auth: AuthenticationService
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    customerDtOptions: DataTables.Settings = {};
    customers: CustomerDataTableViewModel[]
    modalRemove: string = 'customerModalRemove'
    modalEdit: string = 'customerModalEdit'

    ngOnInit() {
        this.initDatatable();
    }

    ngAfterViewInit() {
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    edit(customer: CustomerDataTableViewModel): void {
        this.http.get<CustomerEditViewModel>("/api/customer/update/" + customer.id)
            .subscribe(data => {
                this.ngxSmartModalService.setModalData(data, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            });
    }

    remove(customer: CustomerDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(customer.id, this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.customerDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                    self.http.get<DataTablesResponse>(`api/customer/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.customers = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'customerNumber' },
                { data: 'name' },
                { data: 'address' },
                { data: 'addressAlt' },
                { data: 'zipCode' },
                { data: 'city' },
                { data: 'email' },
                { data: 'website' },
                { data: 'phoneNumber' },
                { data: 'reference' }
            ]
        }
    }
}
