import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.css']
})
export class ModalTemplateComponent {
  @Input() title
  @Input() content
  @Input() cancel
  @Input() confirm

  constructor(public activeModal: NgbActiveModal) { }

}
