import { Component, OnInit } from '@angular/core';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { HttpClient } from '@angular/common/http';
import { SalaryReportViewModel } from '@app/models/SalaryReportViewModel';
import { ReportService } from '@app/services/report.service';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-salary-report',
  templateUrl: './salary-report.component.html',
  styleUrls: ['./salary-report.component.css']
})
export class SalaryReportComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: SalaryReportViewModel = new SalaryReportViewModel()
  dropdownSettings = {}

  users: SimpleViewModel[] = []
  selectedUsers: SimpleViewModel[] = []

  timeTypes: SimpleViewModel[] = []
  selectedTimeTypes: SimpleViewModel[] = []

  isPrinting: boolean = false

  timeTypeSelectChange() {
    this.report.timeTypeIds = this.selectedTimeTypes.map(x => x.id)
  }

  userSelectChange() {
    this.report.userIds = this.selectedUsers.map(x => x.id)
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  ngAfterViewInit() {
    this.http.get<SimpleViewModel[]>("/api/userProfile/getActiveEmployees")
      .subscribe(data => {
        this.users = data;
      })

    this.http.get<SimpleViewModel[]>("/api/timeType/getAll")
      .subscribe(data => {
        this.timeTypes = data;
      })
  }

  print(): void {
    let self = this
    self.isPrinting = true
    this.reportService.downloadPdf(
      'api/report/salary',
      this.report,
      'Användarrapport_' + +new Date() + '.pdf',
      function (success) {
        self.isPrinting = false
      })
  }
}
