import { Component, OnInit } from '@angular/core';
import { ArticleService } from '@app/services/article.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ArticleEditViewModel } from '@app/models/ArticleEditViewModel';

@Component({
  selector: 'app-article-edit-modal',
  templateUrl: './article-edit-modal.component.html',
  styleUrls: ['./article-edit-modal.component.css']
})
export class ArticleEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private articleService: ArticleService
  ) { }

  modalEdit: string = 'articleEditModal';

  articleEditForm = this.formBuilder.group({
    id: [''],
    articleNumber: [''],
    articleNumberVisma: null,
    name: [''],
    type: [''],
    unit: [''],
    balance: ['']
  });
  get form() {
    return this.articleEditForm.controls;
  }

  resetForm() {
    const defaultValues = {
      id: 0,
      articleNumber: null,
      articleNumberVisma: null,
      name: null,
      type: null,
      unit: null,
      balance: 0,
    };
    this.articleEditForm.reset(defaultValues);
  }

  article: ArticleEditViewModel

  clear(): void {
    this.ngxSmartModalService.get(this.modalEdit).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalEdit);
    this.ngxSmartModalService.close(this.modalEdit);
  }

  remove(): void {
    let id = Number(this.articleEditForm.controls['id'].value)
    this.articleService.delete(id).subscribe(
      () => {
        this.toastr.success('Artikeln har tagits bort.');
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalEdit);
        this.ngxSmartModalService.close(this.modalEdit);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.toastr.error('Det gick inte att ta bort artikeln.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.articleService.update(this.articleEditForm.value).subscribe(
      () => {
        this.toastr.success('Artikeln har sparats.');
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalEdit);
        this.ngxSmartModalService.close(this.modalEdit);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.toastr.error('Det gick inte att uppdatera artikeln.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    this.update();
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalEdit).onOpen.subscribe((modal: NgxSmartModalComponent) => {

      let data = modal.getData();
      this.articleEditForm.setValue(data)
      //this.http.get<ArticleEditViewModel>("/api/article/update/?id=" + data.id)
      //  .subscribe(data => {
      //    this.articleEditForm.setValue(data)
      //  })
    })
  }

}
