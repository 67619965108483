import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { TimelogEditViewModel } from '../models/TimelogEditViewModel';
import { MaterialViewModel } from '../models/MaterialViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TimelogService {

  private timelogUrl = '/api/timelog/';

  constructor(private http: HttpClient) { }

  getUpdate(id: number): Observable<TimelogEditViewModel> {
    return this.http.get<TimelogEditViewModel>(this.timelogUrl + `update?id=${id}`, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  save(timelogEditViewModel: TimelogEditViewModel): Observable<TimelogEditViewModel> {
    return this.http.post<TimelogEditViewModel>(this.timelogUrl + 'create', timelogEditViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  update(timelogEditViewModel: TimelogEditViewModel): Observable<TimelogEditViewModel> {
    return this.http.put<TimelogEditViewModel>(this.timelogUrl + 'update', timelogEditViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  saveMaterial(material: MaterialViewModel): Observable<MaterialViewModel> {
    return this.http.post<MaterialViewModel>(this.timelogUrl + 'createMaterial', material, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  updateMaterialAmount(id: number, amount: number): Observable<any> {
    return this.http.put<any>(this.timelogUrl + 'updateMaterialAmount?id=' + id + "&amount=" + amount, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  deleteMaterial(id: number, timeLogId: number): Observable<any> {
    return this.http.delete<any>(this.timelogUrl + 'deleteMaterial?id=' + id + "&timeLogId=" + timeLogId, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  printPdf(projectId: number): Observable<any> {
    return this.http.get<any>(this.timelogUrl + 'downloadPdf?id=' + projectId, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }


}
