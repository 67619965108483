import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SnowlogService } from '@app/services/snowlog.service';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { SnowLogEditViewModel } from '@app/models/SnowLogEditViewModel';
import { DatepickerDefaultOptions } from '../../DatepickerDefaultOptions';

@Component({
  selector: 'app-snow-log-edit-modal',
  templateUrl: './snow-log-edit-modal.component.html',
  styleUrls: ['./snow-log-edit-modal.component.css']
})
export class SnowLogEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private snowlogService: SnowlogService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  modalEdit: string = 'snowLogEditModal';
  date: Date
  datepickerConfig = this.datePickerDefaultOptions.defaults

  snowlogEditForm = this.formBuilder.group({
    id: [''],
    machineId: ['',
      [
        Validators.required
      ]
    ],
    employeeId: ['',
      [
        Validators.required
      ]
    ],
    snowAreaId: ['',
      [
        Validators.required
      ]
    ],
    date: ['',
      [
        Validators.required
      ]
    ],
    time: ['',
      [
        Validators.required,
        Validators.min(0)
      ]
    ],
    sand: [''],
    note: [''],
    archived: ['']
  });
  get form() {
    return this.snowlogEditForm.controls;
  }

  resetForm() {
    const defaultValues = {
      id: 0,
      machineId: null,
      employeeId: null,
      snowAreaId: null,
      date: new Date().toLocaleDateString('sv-SE'),
      time: 0,
      sand: 0,
      note: '',
      archived: false
    };
    this.snowlogEditForm.reset(defaultValues);
  }

  machines: SimpleViewModel[];
  employees: SimpleViewModel[];
  areas: SimpleViewModel[];
  isNew: boolean = false;

  clear(): void {
    this.ngxSmartModalService.get(this.modalEdit).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalEdit);
    this.ngxSmartModalService.close(this.modalEdit);
  }

  save(): void {
    this.snowlogService.save(this.snowlogEditForm.value).subscribe(
      () => {
        this.toastr.success('Snöloggen har sparats.');
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalEdit);
        this.snowlogEditForm.reset();
        this.ngxSmartModalService.close(this.modalEdit);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.toastr.error('Det gick inte att spara snöloggen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.snowlogService.update(this.snowlogEditForm.value).subscribe(
      () => {
        this.toastr.success('Snöloggen har sparats.');
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalEdit);
        this.ngxSmartModalService.close(this.modalEdit);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalEdit).removeData();
        this.toastr.error('Det gick inte att uppdatera snöloggen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {

  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalEdit).onOpen.subscribe((modal: NgxSmartModalComponent) => {

      let modalData = modal.getData();

      if (!modalData.snowLogAreaId) {
        this.isNew = true
        this.resetForm()

        if (modalData && modalData.employeeId) {
          this.snowlogEditForm.controls['employeeId'].reset(modalData.employeeId)
        }

      }
      else {
        this.http.get<SnowLogEditViewModel>("/api/snowlog/update/?snowLogAreaId=" + modalData.snowLogAreaId)
          .subscribe(data => {
            this.isNew = false;
            this.snowlogEditForm.setValue(data)

            //convert UTC to local time
            this.date = new Date(data.date)
            this.snowlogEditForm.patchValue({ date: new Date(data.date) })
          })
      }

      this.http.get<SimpleViewModel[]>("/api/machine/getAll")
        .subscribe(data => {
          this.machines = data;
        });

      this.http.get<SimpleViewModel[]>("/api/userProfile/getActiveEmployees")
        .subscribe(data => {
          this.employees = data;
        });

      this.http.get<SimpleViewModel[]>("/api/snowArea/GetAll")
        .subscribe(data => {
          this.areas = data;

          if (modalData && modalData.snowAreaId) {
            this.snowlogEditForm.controls['snowAreaId'].reset(modalData.snowAreaId)
          }

        });
    })
  }

}
