import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from "@angular/common/http";
import { defineLocale } from 'ngx-bootstrap/chronos';
import { svLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '@app/services/project.service';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ProjectEditViewModel } from '@app/models/ProjectEditViewModel'
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-project-modal-edit',
  templateUrl: './project-modal-edit.component.html',
  styleUrls: ['./project-modal-edit.component.css']
})
export class ProjectModalEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  projectEditForm = this.formBuilder.group({
    id: [''],
    customerId: ['',
      [
        Validators.required
      ]
    ],
    parentProjectId: [''],
    projectResponsibleId: [''],
    customerReference: [''],
    projectLittra: [''],
    title: [''],
    description: [''],
    endDate: [''],
    budgetTime: [''],
    budgetCost: [''],
    active: [''],
    billable: [''],
    department: ['']
  });
  get form() { return this.projectEditForm.controls; }
  resetForm() {
    const defaultValues = {
      id: null,
      customerId: null,
      parentProjectId: null,
      projectResponsibleId: null,
      customerReference: '',
      projectLittra: '',
      title: '',
      description: '',
      endDate: null,
      budgetTime: null,
      budgetCost: null,
      active: true,
      billable: true,
      department: 0
    };
    this.projectEditForm.reset(defaultValues);
  }

  customers: SimpleViewModel[];
  activeParentProjects: SimpleViewModel[];
  projectResponsible: SimpleViewModel[];
  isNew: boolean = false;
  endDate: Date
  selectedDepartment: number = 0;

  onDepartmentSelected(selected): void {
    this.selectedDepartment = selected;
  }

  clear(): void {
    this.ngxSmartModalService.get('projectModalEdit').removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, 'projectModalEdit');
    this.ngxSmartModalService.close('projectModalEdit');
  }

  save(): void {
    this.projectService.save(this.projectEditForm.value).subscribe(
      (projectEdit: ProjectEditViewModel) => {
        this.toastr.success('Projektet har sparats.');
        this.ngxSmartModalService.get('projectModalEdit').removeData();
        this.ngxSmartModalService.setModalData({ success: true }, 'projectModalEdit');
        this.projectEditForm.reset();
        this.ngxSmartModalService.close('projectModalEdit');
      },
      (error) => {
        this.ngxSmartModalService.get('projectModalEdit').removeData();
        this.toastr.error('Det gick inte att spara projektet.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.projectService.update(this.projectEditForm.value).subscribe(
      (projectEdit: ProjectEditViewModel) => {
        this.toastr.success('Projektet har sparats.');
        this.ngxSmartModalService.get('projectModalEdit').removeData();
        this.ngxSmartModalService.setModalData({ success: true }, 'projectModalEdit');
        this.ngxSmartModalService.close('projectModalEdit');
      },
      (error) => {
        this.ngxSmartModalService.get('projectModalEdit').removeData();
        this.toastr.error('Det gick inte att uppdatera projektet.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngOnInit(): void {
    defineLocale('sv', svLocale);
    this.localeService.use('sv');
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectModalEdit').onOpen.subscribe((modal: NgxSmartModalComponent) => {

      this.http.get<SimpleViewModel[]>("/api/customer/getAll")
        .subscribe(data => {
          this.customers = data;
        });

      this.http.get<SimpleViewModel[]>("/api/project/activeParents")
        .subscribe(data => {
          this.activeParentProjects = data;
        });

      this.http.get<SimpleViewModel[]>("/api/userProfile/projectResponsible")
        .subscribe(data => {
          this.projectResponsible = data;
        });

      var data = modal.getData();

      //edit, otherwise its a new project
      if (data !== undefined) {
        this.isNew = false;
        this.projectEditForm.setValue(data);
        this.selectedDepartment = data.department;
      } else {
        this.isNew = true;
        this.resetForm();
      }
    });
  }
}
