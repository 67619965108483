import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MaterialReportViewModel } from '@app/models/MaterialReportViewModel';
import { ReportService } from '@app/services/report.service';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-material-report',
  templateUrl: './material-report.component.html',
  styleUrls: ['./material-report.component.css']
})
export class MaterialReportComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: MaterialReportViewModel = new MaterialReportViewModel()
  dropdownSettings = {}
  isPrinting: boolean = false

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  print(): void {
    let self = this
    self.isPrinting = true
    this.reportService.downloadPdf(
      'api/report/material', this.report,
      'Materialrapport_' + +new Date() + '.pdf',
      function (success) {
        self.isPrinting = false
      })
  }

}
