import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-user-travel-logs',
  templateUrl: './user-travel-logs.component.html',
  styleUrls: ['./user-travel-logs.component.css']
})
export class UserTravelLogsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService
  ) { }

  ngOnInit() {
  }

}
