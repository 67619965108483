import { Injectable } from '@angular/core';

@Injectable()
export class DatatableDefaultOptions {
  
  defaults: DataTables.Settings = {
    pagingType: 'simple_numbers',
    pageLength: 10,
    autoWidth: false,
    serverSide: true,
    processing: true,
    searchDelay: 500,
    language: {
      url: "../assets/localization/swedish.datatables.json"
    },
    dom: '<"row"<"col-md-2"l><"col-md-2"r><"#datatable-filter-wrapper.col-md-4"><"col-md-4 text-right"f>><"row"t><"row"<"col-sm-12"<"bottom pull-left"i><"bottom pull-right"p>>>',
    search: {
      caseInsensitive: true
    },
    stateSave: true,
    stateDuration: 60 * 60 * 24 * 7 * 52, //save state 1 year
    stateSaveCallback: function (settings: DataTables.SettingsLegacy, data) {
      localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
    },
    stateLoadCallback: function (settings) {
      return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
    }
  };
  
}
