import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimelogService } from '@app/services/timelog.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaterialViewModel } from '@app/models/MaterialViewModel';
import { MessageService } from '../../../../services/message.service';
import { Messages } from '../../../../models/enums/Messages';

@Component({
  selector: 'app-project-modal-material-edit',
  templateUrl: './project-modal-material-edit.component.html',
  styleUrls: ['./project-modal-material-edit.component.css']
})
export class ProjectModalMaterialEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private timelogService: TimelogService,
    private toastr: ToastrService,
    private messageService: MessageService
  ) {
    this.formGroup = this.formBuilder.group({
      'id': [''],
      'articleId': [''],
      'timelogId': ['', [Validators.required]],
      'name': ['', [Validators.required]],
      'unit': [''],
      'amount': ['', [Validators.required]],
    })
  }

  @Output() messageEvent = new EventEmitter<string>();
  defaultValues = {
    id: null,
    articleId: null,
    timelogId: null,
    name: null,
    unit: null,
    amount: null
  }

  timeLogId: number
  materials: MaterialViewModel[] = []
  filteredMaterials: Observable<any[]>
  materialOptions: any[]
  isNew: boolean = false
  modalName: string = 'projectModalMaterialEdit'
  formGroup: FormGroup
  materialName: string = ''
  datatableName: string = 'projectTimeTable'
  get form() { return this.formGroup.controls; }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData()
    this.formGroup.reset(this.defaultValues)
    this.materials = []
  }

  onMaterialSearch(value: any) {
    let articleId = $('option[value="' + value + '"]').data('id')
    this.formGroup.patchValue({ articleId: articleId })
  }

  onUpdateMaterialAmount(id: number, newAmount: number) {
    let oldAmount = this.materials.find(x => x.id == id).amount
    if (oldAmount != newAmount) {
      this.update(id, newAmount)
    }
  }

  update(id: number, amount: number): void {
    this.timelogService.updateMaterialAmount(id, amount).subscribe(
      () => {
        this.toastr.success('Material ändrat!');
        this.materials.find(x => x.id == id).amount = amount
        this.messageEvent.emit(this.datatableName + ':reload')
        this.messageService.sendMessage(Messages.ReloadProjectStats)
      },
      (error) => {
        this.toastr.error('Det gick inte att ändra material.');
      },
      () => {
        //complete
      }
    );
  }

  add(): void {
    this.formGroup.patchValue({ timelogId: this.timeLogId })

    if (!this.formGroup.valid) {
      return
    }

    this.timelogService.saveMaterial(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Material skapat!');
        this.formGroup.reset()
        this.getMaterial()
        this.messageEvent.emit(this.datatableName + ':reload')
        this.messageService.sendMessage(Messages.ReloadProjectStats)
      },
      (error) => {
        this.toastr.error('Det gick inte att skapa material.');
      },
      () => {
        //complete
      }
    );
  }

  remove(index: number): void {
    let materialId = this.materials[index].id
    this.timelogService.deleteMaterial(materialId, this.timeLogId).subscribe(
      () => {
        this.toastr.success('Material borttaget!');
        this.materials.splice(index, 1)
        this.messageEvent.emit(this.datatableName + ':reload')
        this.messageService.sendMessage(Messages.ReloadProjectStats)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort materialet.');
      },
      () => {
        //complete
      }
    );
  }

  getMaterial(): void {
    this.http.get<MaterialViewModel[]>("/api/timelog/articles/" + this.timeLogId)
      .subscribe(data => {
        this.materials = data
      });
  }

  getMaterialAutoComplete(): void {
    this.http.get<any[]>("/api/article/autocomplete/")
      .subscribe(data => {
        this.materialOptions = data
      });
  }

  ngOnInit() {
  }

  onSubmit() {

  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.timeLogId = modal.getData()
      this.getMaterial()
      this.getMaterialAutoComplete()
    });
  }
}
