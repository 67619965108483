import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DatatableDefaultOptions } from '../../DatatableDefaultOptions';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { RepairLogDatatableViewModel } from '../../models/RepairLogDatatableViewModel';
import { DataTablesRequest } from '../../models/DataTablesRequest';
import { DataTablesResponse } from '../../models/DataTablesResponse';
import { RepairLogEditViewModel } from '../../models/RepairLogEditViewModel';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-repair-logs-datatable',
    templateUrl: './repair-logs-datatable.component.html',
    styleUrls: ['./repair-logs-datatable.component.css']
})
export class RepairLogsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private http: HttpClient,
        private router: Router,
        private auth: AuthenticationService
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    repairLogDtOptions: DataTables.Settings = {};
    repairLogs: RepairLogDatatableViewModel[]
    modalEdit: string = 'repairLogEditModal'
    modalRemove: string = 'repairLogRemoveModal'
    showHistory: boolean = false

    //machineId sould be set by parent component
    machineId: number = null

    edit(repairLog: RepairLogDatatableViewModel): void {
        this.ngxSmartModalService.resetModalData(this.modalEdit)

        this.http.get<RepairLogEditViewModel>("/api/repairLog/update/" + repairLog.id)
            .subscribe(data => {
                this.ngxSmartModalService.setModalData(data, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            });
    }

    show(repairLog: RepairLogDatatableViewModel): void {
        this.router.navigate(['/project/' + repairLog.projectId]);
    }

    remove(repairLog: RepairLogDatatableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(repairLog.id), this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    ngOnInit() {
        this.initDatatable()
    }

    ngAfterViewInit() {
        this.moveCustomFilters()
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
      this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
      this.showHistory = localStorage.getItem('DataTables_showHistory_repairLogs') == "true"
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.repairLogDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                        + "&showHistory=" + self.showHistory
                        + (self.machineId ? "&machineId=" + self.machineId : "")
                    self.http.get<DataTablesResponse>(`api/repairLog/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.repairLogs = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'userName' },
                { data: 'projectTitle' },
                { data: 'mileage' },
                { data: 'time' },
                { data: 'date' },
                { data: 'note' }
            ]
        }
    }

    moveCustomFilters(): void {
        this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
                let datatableWrapper = (dtInstance.table('table').container())
                let dtId = $(datatableWrapper).attr('id')
                var closestFilter = $('#' + dtId).siblings('.dt-filter')
                $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
            })
        )
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
      localStorage.setItem('DataTables_showHistory_repairLogs', this.showHistory.toString())
    }
}
