import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { RoleDataTableViewModel } from '@app/models/RoleDataTableViewModel';

@Component({
    selector: 'app-permissions-datatable',
    templateUrl: './permissions-datatable.component.html',
    styleUrls: ['./permissions-datatable.component.css']
})
export class PermissionsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private router: Router,
        private http: HttpClient
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    permissionsDtOptions: DataTables.Settings = {};
    roles: RoleDataTableViewModel[]
    modalEdit: string = 'roleModalEdit';
    modalRemove: string = 'roleModalRemove';

    edit(role: RoleDataTableViewModel): void {
        //this.http.get<RoleDataTableViewModel>("/api/roles/update/?id=" + role.id)
        //  .subscribe(article => {
        //    this.ngxSmartModalService.setModalData(role, this.modalEdit);
        //    this.ngxSmartModalService.open(this.modalEdit);
        //  })
    }

    remove(role: RoleDataTableViewModel): void {
        //this.http.delete<RoleDataTableViewModel>("/api/roles/delete/?id=" + role.id)
        //  .subscribe(article => {
        //
        //  })
    }

    ngOnInit() {
        this.initDatatable()
    }

    ngAfterViewInit() {
        //this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        //this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.permissionsDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                    self.http.get<DataTablesResponse>(`api/role/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.roles = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
                columns: [
                    { data: 'name' },
                    //{ data: 'description' },
                    //{ data: 'level' }
                ]
            }
        }
    }

}
