import { Injectable } from '@angular/core';

@Injectable()
export class DatepickerDefaultOptions {
  
  defaults: any = {
    dateInputFormat: 'YYYY-MM-DD',
    containerClass: 'theme-default',
    adaptivePosition: true,
    isAnimated: true,
    customTodayClass: 'datepicker-today',
    selectFromOtherMonth: true
  };
}
