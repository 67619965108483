import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '../../../services/project.service';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ProjectCostDatatableComponent } from '../datatables/project-cost-datatable/project-cost-datatable.component';
import { TimelogService } from '../../../services/timelog.service';
import { ProjectTimeDatatableComponent } from '../datatables/project-time-datatable/project-time-datatable.component';
import { GravelLogsDatatableComponent } from '../../../datatables/gravel-logs-datatable/gravel-logs-datatable.component';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit, AfterViewInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private timelogService: TimelogService
  ) { }

  @ViewChild(GravelLogsDatatableComponent) gravelLogDatatable;
  @ViewChild(ProjectCostDatatableComponent) projectCostDatatable;
  @ViewChild(ProjectTimeDatatableComponent) projectTimeDatatable;
  //add snowlogs dt
  //add controls dt

  //project = {};
  projectId: number

  add(): void {
    let data = {
      id: null,
      projectId: this.projectId,
      userId: null
    }
    this.ngxSmartModalService.getModal('projectModalGravellogEdit').removeData()
    this.ngxSmartModalService.getModal('projectModalGravellogEdit').setData(data)
    this.ngxSmartModalService.getModal('projectModalGravellogEdit').open()
  }

  downloadTimelogPdf(): void {
    this.timelogService.printPdf(this.projectId).subscribe(
      () => {
        console.log("pdf nedladdad")
      },
      (error) => {
        this.toastr.error('Det gick inte att ladda ner PDF.')
      },
      () => {
        //complete
      }
    );
  }

  getMarkedTimelogs(): number[] {
    let markedTimelogs: number[] = []
    $.each($('#projectTimeTable tbody input[type="checkbox"]'), function (idx, obj) {
      if ($(obj).attr('disabled') !== 'disabled' && $(obj).is(':checked')) {
        markedTimelogs.push(Number($(obj).val()))
      }
    });
    return markedTimelogs
  }

  ngOnInit() {
    let projectId = Number(this.route.snapshot.paramMap.get('id'))
    this.projectId = projectId
    this.projectTimeDatatable.projectId = projectId
    this.gravelLogDatatable.projectId = projectId
    this.projectCostDatatable.projectId = projectId
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectModalCostAdd').onClose.subscribe((modal: NgxSmartModalComponent) => {
      let modalData = modal.getData()
      if (modalData && modalData.success === true) {
        this.projectCostDatatable.reloadDatatable();
      }
    });
  }

}
