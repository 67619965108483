import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { RepairLogService } from '@app/services/repairLog.service';

@Component({
  selector: 'app-repair-log-remove-modal',
  templateUrl: './repair-log-remove-modal.component.html',
  styleUrls: ['./repair-log-remove-modal.component.css']
})
export class RepairLogRemoveModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private repairLogService: RepairLogService
  ) { }

  repairLogId: number
  modalName: string = 'repairLogRemoveModal'

  clear(): void {
    this.repairLogId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.repairLogId) {
      return;
    }

    this.repairLogService.delete(this.repairLogId).subscribe(
      () => {
        this.toastr.success('Reparationen har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort reparationen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.repairLogId = modal.getData()
    });
  }

}
