
// Auto generated by typewriter


export class UserReportViewModel {
    
    fromDate: Date = null;
    toDate: Date = null;
    userIds: number[] = [];
}
