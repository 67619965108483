import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
  }

}
