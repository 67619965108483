import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';
import { SnowLogsDatatableComponent } from '../../../datatables/snow-logs-datatable/snow-logs-datatable.component';

@Component({
  selector: 'app-snowlog-details',
  templateUrl: './snowlog-details.component.html',
  styleUrls: ['./snowlog-details.component.css']
})
export class SnowlogDetailsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
        private route: ActivatedRoute
  ) { }

  @ViewChild(SnowLogsDatatableComponent) snowLogDatatable;
  snowAreaId: number = null

  ngOnInit() {
    this.snowAreaId = Number(this.route.snapshot.paramMap.get('id'))
    this.snowLogDatatable.snowAreaId = this.snowAreaId
  }

  editSnowLogModal(): void {
    let data: any = {}
    data.snowAreaId = this.snowAreaId
    this.ngxSmartModalService.setModalData(data, 'snowLogEditModal')
    this.ngxSmartModalService.open('snowLogEditModal')
  }

}
