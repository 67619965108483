import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { MachineService } from '@app/services/machine.service';

@Component({
  selector: 'app-machine-modal-remove',
  templateUrl: './machine-modal-remove.component.html',
  styleUrls: ['./machine-modal-remove.component.css']
})
export class MachineModalRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private machineService: MachineService
  ) { }

  machineId: number
  modalName: string = 'machineModalRemove'

  clear(): void {
    this.machineId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }


  remove(): void {
    this.machineService.delete(this.machineId).subscribe(
      () => {
        this.toastr.success('Maskinen har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort maskinen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.machineId = modal.getData()
    });
  }

}
