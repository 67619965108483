import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { GravelLogEditViewModel } from '../models/GravelLogEditViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GravellogService {

  private gravellogUrl = '/api/gravellog/';

  constructor(private http: HttpClient) { }

  getUpdate(id: number): Observable<GravelLogEditViewModel> {
    return this.http.get<GravelLogEditViewModel>(this.gravellogUrl + `update?id=${id}`, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  save(gravellogEditViewModel: GravelLogEditViewModel): Observable<any> {
    return this.http.post<GravelLogEditViewModel>(this.gravellogUrl + 'create', gravellogEditViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  update(gravellogEditViewModel: GravelLogEditViewModel): Observable<any> {
    return this.http.put<GravelLogEditViewModel>(this.gravellogUrl + 'update', gravellogEditViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.gravellogUrl + 'delete?id=' + id, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }


}
