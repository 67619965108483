﻿
// Auto generated by typewriter


export class MachineReportViewModel {
    
    fromDate: Date = null;
    toDate: Date = null;
    machineIds: number[] = [];
    service: boolean = false;
    repair: boolean = false;
}
