import { Component, OnInit } from '@angular/core';
import { GravelReportViewModel } from '@app/models/GravelReportViewModel';
import { HttpClient } from '@angular/common/http';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ReportService } from '@app/services/report.service';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-gravel-report',
  templateUrl: './gravel-report.component.html',
  styleUrls: ['./gravel-report.component.css']
})
export class GravelReportComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: GravelReportViewModel = new GravelReportViewModel()
  dropdownSettings = {}

  suppliers: SimpleViewModel[] = []
  selectedSuppliers: SimpleViewModel[] = []

  isPrinting: boolean = false

  supplierSelectChange() {
    this.report.supplierIds = this.selectedSuppliers.map(x => x.id)
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  ngAfterViewInit() {
    this.http.get<SimpleViewModel[]>("/api/supplier/getAll")
      .subscribe(data => {
        this.suppliers = data;
      })
  }



  print(): void {
    let self = this
    this.isPrinting = true
    this.reportService.downloadPdf(
      'api/report/gravel',
      this.report,
      'Grusrapport_' + +new Date() + '.pdf',
      function (success) {
        self.isPrinting = false
      })
  }

}
