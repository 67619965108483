import { Component, OnInit } from '@angular/core';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { HttpClient } from '@angular/common/http';
import { MachineReportViewModel } from '@app/models/MachineReportViewModel';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-machine-report',
  templateUrl: './machine-report.component.html',
  styleUrls: ['./machine-report.component.css']
})
export class MachineReportComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: MachineReportViewModel = new MachineReportViewModel()
  dropdownSettings = {}

  machines: SimpleViewModel[] = []
  selectedMachines: SimpleViewModel[] = []

  isPrinting: boolean = false

  machineSelectChange() {
    this.report.machineIds = this.selectedMachines.map(x => x.id)
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  ngAfterViewInit() {
    this.http.get<SimpleViewModel[]>("/api/machine/getAll")
      .subscribe(data => {
        this.machines = data;
      })
  }



  print(): void {
    this.isPrinting = true
    this.downloadPdf('api/report/machine', this.report)
  }

  downloadPdf(url, data): void {
    var self = this
    var xhr = new XMLHttpRequest();
    xhr.open('post', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.responseType = 'blob';
    xhr.send(JSON.stringify(data));

    xhr.onloadend = function (data: any) {
      console.log(data)
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: 'application/pdf' });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'Maskin_rapport' + new Date().toLocaleDateString('sv-SE') + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        self.isPrinting = false;
      } else {
        // error
        self.isPrinting = false;
      }
    };
  }

}
