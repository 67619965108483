﻿
// Auto generated by typewriter


export class MaterialReportViewModel {
    
    fromDate: Date = null;
    toDate: Date = null;
    articleNumber: string = null;
}
