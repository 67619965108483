import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { SnowAreaService } from '@app/services/snowArea.service';

@Component({
  selector: 'app-snow-area-remove-modal',
  templateUrl: './snow-area-remove-modal.component.html',
  styleUrls: ['./snow-area-remove-modal.component.css']
})
export class SnowAreaRemoveModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private snowAreaService: SnowAreaService
  ) { }

  snowAreaId: number
  modalName: string = 'snowAreaRemoveModal'

  clear(): void {
    this.snowAreaId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.snowAreaId) {
      return;
    }

    this.snowAreaService.delete(this.snowAreaId).subscribe(
      () => {
        this.toastr.success('Området har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort området.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.snowAreaId = modal.getData()
    });
  }

}
