import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ControlEditViewModel } from '@app/models/ControlEditViewModel';
import { ControlItemViewModel } from '@app/models/ControlItemViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ControlService {
  private url = '/api/control/';
  constructor(private http: HttpClient) { }
  get(id: number): Observable<any> {
    return this.http.get<any>(this.url + `get?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  getUpdate(id: number): Observable<ControlEditViewModel> {
    return this.http.get<ControlEditViewModel>(this.url + `update?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  save(something: ControlEditViewModel): Observable<any> {
    return this.http.post<ControlEditViewModel>(this.url + 'create', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  update(something: ControlEditViewModel): Observable<any> {
    return this.http.put<ControlEditViewModel>(this.url + 'update', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + `delete?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  saveControlItem(controlItem: ControlItemViewModel): Observable<any> {
    return this.http.post<ControlItemViewModel>(this.url + `createControlItem`, controlItem, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  saveControlItems(controlItems: ControlItemViewModel[]): Observable<any> {
    return this.http.post<ControlItemViewModel[]>(this.url + `createControlItems`, controlItems, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  updateControlItem(something: ControlItemViewModel): Observable<any> {
    return this.http.put<ControlEditViewModel>(this.url + 'updateControlItem', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  deleteControlItem(id: number): Observable<any> {
    return this.http.delete<any>(this.url + `deleteControlItem?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }

}
