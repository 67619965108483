import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { GravelPriceEditViewModel } from '@app/models/GravelPriceEditViewModel';
import { ArticleService } from '@app/services/article.service';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { GravelPriceViewModel } from '@app/models/GravelPriceViewModel';

@Component({
  selector: 'app-gravel-price-edit-modal',
  templateUrl: './gravel-price-edit-modal.component.html',
  styleUrls: ['./gravel-price-edit-modal.component.css']
})
export class GravelPriceEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private articleService: ArticleService
  ) {
    this.gravelPriceEditForm = this.formBuilder.group({
      id: [''],
      articleId: ['', [Validators.required]],
      distanceId: [''],
      isTrailer: [''],
    });
  }

  gravelPriceEditForm: FormGroup
  modalName: string = 'gravelPriceEditModal';
  articles: SimpleViewModel[]
  distances: SimpleViewModel[]

  get form() {
    return this.gravelPriceEditForm.controls;
  }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.gravelPriceEditForm.reset()
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  update(): void {
    this.articleService.updateGravelPrice(this.gravelPriceEditForm.value).subscribe(
      () => {
        this.toastr.success('Gruspriset har sparats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att uppdatera gruspriset.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    this.update()
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {

      this.http.get<GravelPriceViewModel[]>("/api/gravelLog/distances")
        .subscribe(data => {
          this.distances = data;
        });

      this.http.get<GravelPriceViewModel[]>("/api/article/getAll")
        .subscribe(data => {

          data = data.map((i) => { i.name = i.name + ' (Visma: ' + i.articleNumber + ')'; return i })

          this.articles = data;

          let modalData: GravelPriceEditViewModel = modal.getData();
          this.gravelPriceEditForm.controls['id'].patchValue(modalData.id)
          this.gravelPriceEditForm.controls['articleId'].patchValue(modalData.articleId)
          this.gravelPriceEditForm.controls['distanceId'].patchValue(modalData.distanceId)
          this.gravelPriceEditForm.controls['isTrailer'].patchValue(modalData.isTrailer)
        });
    })
  }

}
