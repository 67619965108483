import { Component, OnInit } from '@angular/core';
import { SnowReportViewModel } from '@app/models/SnowReportViewModel';
import { ReportService } from '@app/services/report.service';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-snow-report',
  templateUrl: './snow-report.component.html',
  styleUrls: ['./snow-report.component.css']
})
export class SnowReportComponent implements OnInit {

  constructor(
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: SnowReportViewModel = new SnowReportViewModel()
  dropdownSettings = {}
  isPrinting: boolean = false

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  print(): void {
    let self = this
    self.isPrinting = true
    this.reportService.downloadPdf(
      'api/report/snow',
      this.report,
      'Snölogg_rapport_' + +new Date() + '.pdf',
      function (success) {
        self.isPrinting = false
      })
  }
}
