import { Component, OnInit } from '@angular/core';
import { UserMachineReportViewModel } from '@app/models/UserMachineReportViewModel';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { HttpClient } from '@angular/common/http';
import { DatepickerDefaultOptions } from '../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-user-machine-report',
  templateUrl: './user-machine-report.component.html',
  styleUrls: ['./user-machine-report.component.css']
})
export class UserMachineReportComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: UserMachineReportViewModel = new UserMachineReportViewModel()
  dropdownSettings = {}

  users: SimpleViewModel[] = []
  selectedUsers: SimpleViewModel[] = []

  workTypes: SimpleViewModel[] = []
  selectedWorkTypes: SimpleViewModel[] = []

  machines: SimpleViewModel[] = []
  selectedMachines: SimpleViewModel[] = []

  customers: SimpleViewModel[] = []
  selectedCustomers: SimpleViewModel[] = []

  isPrinting: boolean = false

  userSelectChange() {
    this.report.userIds = this.selectedUsers.map(x => x.id)
  }

  workTypeSelectChange() {
    this.report.workTypeIds = this.selectedWorkTypes.map(x => x.id)
  }

  machineSelectChange() {
    this.report.machineIds = this.selectedMachines.map(x => x.id)
  }

  customerSelectChange() {
    this.report.customerIds = this.selectedCustomers.map(x => x.id)
  }

  ngOnInit() {
    this.users = []
    this.selectedUsers = []
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      enableCheckAll: true
    };
  }

  ngAfterViewInit() {
    this.http.get<SimpleViewModel[]>("/api/userProfile/getActiveEmployees")
      .subscribe(data => {
        this.users = data;
      })

    this.http.get<SimpleViewModel[]>("/api/workType/getAll")
      .subscribe(data => {
        this.workTypes = data;
      })

    this.http.get<SimpleViewModel[]>("/api/machine/getAll")
      .subscribe(data => {
        this.machines = data;
      })

    this.http.get<SimpleViewModel[]>("/api/customer/getAll")
      .subscribe(data => {
        this.customers = data;
      })
  }



  print(): void {
    this.isPrinting = true
    this.downloadPdf('api/report/userMachine', this.report)
  }

  downloadPdf(url, data): void {
    var self = this
    var xhr = new XMLHttpRequest();
    xhr.open('post', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.responseType = 'blob';
    xhr.send(JSON.stringify(data));

    xhr.onloadend = function (data: any) {
      console.log(data)
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: 'application/pdf' });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'Användarrapport_maskiner_' + new Date().toLocaleDateString('sv-SE') + '.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
        self.isPrinting = false;
      } else {
        // error
        self.isPrinting = false;
      }
    };
  }

}
