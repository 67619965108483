import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.isUserAuthenticated()) {
      return true;
    }

    return this.authenticationService.getCurrentUser()
      .pipe(
        map(user => {
          if (user && user.id > 0) {
            this.authenticationService.currentUser = user;
            this.authenticationService.setIsAdmin()
            return true;
          }
          else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
          }
        }));
  }
}
