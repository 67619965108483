import { Component, OnInit, ViewChild } from '@angular/core';
import { SnowLogDataTableViewModel } from '../../models/SnowLogDataTableViewModel';
import { DataTableDirective } from 'angular-datatables';
import { DatatableDefaultOptions } from '../../DatatableDefaultOptions';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DataTablesResponse } from '../../models/DataTablesResponse';
import { HttpClient } from '@angular/common/http';
import { DataTablesRequest } from '../../models/DataTablesRequest';
import { AuthenticationService } from '../../services/authentication.service';
import { DatepickerDefaultOptions } from '../../DatepickerDefaultOptions';

@Component({
  selector: 'app-snow-logs-datatable',
  templateUrl: './snow-logs-datatable.component.html',
  styleUrls: ['./snow-logs-datatable.component.css']
})
export class SnowLogsDatatableComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private dataTableOptions: DatatableDefaultOptions,
    private http: HttpClient,
    private auth: AuthenticationService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  @ViewChild(DataTableDirective)
  private datatableElement: DataTableDirective;
  snowLogDtOptions: DataTables.Settings = {};
  snowLogs: SnowLogDataTableViewModel[]
  showHistory: boolean = false
  filterFromDate: Date
  filterToDate: Date
  showDateFilters: boolean = false
  datepickerConfig = this.datePickerDefaultOptions.defaults

  //could be used to show snowlogs per area or user
  userId: number = null
  snowAreaId: number = null

  modalEdit: string = 'snowLogEditModal'
  modalRemove: string = 'snowLogRemoveModal'

  toggleDatePickers(): void {
    this.showDateFilters = !this.showDateFilters
  }

  dateChanged() {
    this.reloadDatatable()
  }

  edit(snowLog: SnowLogDataTableViewModel): void {
    let data = {
      snowLogAreaId: snowLog.snowLogAreaId
    }
    this.ngxSmartModalService.resetModalData(this.modalEdit)
    this.ngxSmartModalService.setModalData(data, this.modalEdit);
    this.ngxSmartModalService.open(this.modalEdit);
  }

  remove(snowLog: SnowLogDataTableViewModel): void {
    let data = {
      snowLogAreaId: snowLog.snowLogAreaId,
      snowLogId: snowLog.id
    }
    this.ngxSmartModalService.resetModalData(this.modalEdit)
    this.ngxSmartModalService.setModalData(data, this.modalRemove);
    this.ngxSmartModalService.open(this.modalRemove);
  }

  ngOnInit() {
    this.initDatatable()
  }

  ngAfterViewInit() {
    this.moveCustomFilters()
    this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    this.reloadDatatableOnModalCloseSuccess(this.modalRemove)

  }

  ngAfterContentInit() {
    this.showHistory = localStorage.getItem('DataTables_showHistory_snowLogs') == "true"
  }


  initDatatable(): void {
    let self = this
    let lastDraw = 0
    this.snowLogDtOptions = {
      ...this.dataTableOptions.defaults, ...{
        ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {

          let request = JSON.stringify(dataTablesParameters)
            + (self.userId ? "&userId=" + self.userId : "")
            + (self.snowAreaId ? "&snowAreaId=" + self.snowAreaId : "")
            + "&showHistory=" + self.showHistory
            + (self.filterFromDate ? "&fromDate=" + self.filterFromDate.toJSON() : "")
            + (self.filterToDate ? "&toDate=" + self.filterToDate.toJSON() : "")

          self.http.get<DataTablesResponse>(`api/SnowLog/Datatable?request=${request}`).subscribe(response => {
            if (response.draw < lastDraw) {
              return;
            }
            lastDraw = response.draw
            self.snowLogs = response.data

            let callbackResponse: DataTablesResponse = {
              recordsTotal: response.recordsTotal,
              recordsFiltered: response.recordsFiltered,
              data: [],
              draw: 0
            }
            callback(callbackResponse);
          });
        },
        columns: [
          { data: 'machine' },
          { data: 'employee' },
          { data: 'area' },
          { data: 'hours' },
          { data: 'sand' },
          { data: 'date' },
          { data: 'note' }
        ]
      }
    }
  }

  moveCustomFilters(): void {
    this.datatableElement.dtInstance.then(
      (dtInstance: DataTables.Api) => dtInstance.on('preInit.dt', function () {
        let datatableWrapper = (dtInstance.table('table').container())
        let dtId = $(datatableWrapper).attr('id')
        var closestFilter = $('#' + dtId).siblings('.dt-filter')
        $('#' + dtId + ' #datatable-filter-wrapper').append(closestFilter)
      })
    )
  }

  reloadDatatableOnModalCloseSuccess(modalName: string) {
    this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
      let data = modal.getData()
      if (data && data.success === true) {
        this.reloadDatatable()
      }
    });
  }

  reloadDatatable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    localStorage.setItem('DataTables_showHistory_snowLogs', this.showHistory.toString())
  }
}
