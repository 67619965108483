import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '@app/services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-modal-edit',
  templateUrl: './user-modal-edit.component.html',
  styleUrls: ['./user-modal-edit.component.css']
})
export class UserModalEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastr: ToastrService
  ) { }

  isNew: boolean = false;
  modalName: string = 'userModalEdit'
  formGroup = this.formBuilder.group({
    id: [''],
    userName: ['', [Validators.required]],
    password: [''],
    roles: ['', [Validators.required]],
    email: [''],
    employmentNumber: [''],
    firstName: [''],
    lastName: [''],
    phoneNumber: ['']
  });
  get form() { return this.formGroup.controls; }

  allRoles = [];
  selectedRoles = [];
  roleDropdownSettings = {};

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.formGroup.reset();
    this.selectedRoles = []
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.userService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Användaren har sparats.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.formGroup.reset();
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att spara användaren.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.userService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Användaren har sparats.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att uppdatera användaren.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  onChange() {
    //sync the form value with dropdown
    this.formGroup.controls['roles'].patchValue(this.selectedRoles.map(x => x.id))
  }

  ngOnInit() {

    this.userService.getRoles().subscribe(
      (data) => {
        this.allRoles = data
      },
      (error) => {
        this.toastr.error('Det gick inte att hämta roller.');
      },
      () => {
        //complete
      }
    );

    this.roleDropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Välj alla',
      unSelectAllText: 'Ta bort alla',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll: false
    };
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var data = modal.getData();

      //edit, otherwise its new
      if (data !== undefined) {
        this.isNew = false;
        this.formGroup.setValue(data);
        this.selectedRoles = this.allRoles.filter(role => {
          if (data.roles.includes(role.id)) {
            return true
          }
          return false
        })
      } else {
        this.isNew = true;
        this.formGroup.reset();
      }
    });
  }
}
