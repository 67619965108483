import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DocumentViewModel } from '@app/models/DocumentViewModel';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { Messages } from '../../../models/enums/Messages';

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.css']
})
export class ProjectDocumentsComponent implements OnInit, OnDestroy {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text == Messages.ReloadDocuments) {
        this.getDocuments()
      }
    });
  }
  subscription: Subscription;
  documents: DocumentViewModel[] = [];
  images: DocumentViewModel[] = [];

  random(): string {
    return Math.random().toString(36).substr(2, 9);
  }

  get showDownloadButton(): boolean {
    let documentsMarkedForDownloadCount = this.documents.filter((x) => x.markedForDownload === true).length;
    let imagesMarkedForDownloadCount = this.images.filter((x) => x.markedForDownload === true).length;
    return documentsMarkedForDownloadCount > 0 || imagesMarkedForDownloadCount > 0
  }

  open(data: DocumentViewModel): void {
    this.ngxSmartModalService.setModalData(data, 'projectModalDocument');
    this.ngxSmartModalService.getModal('projectModalDocument').open();
  }

  getDocuments(): void {
    var self = this
    let id = this.route.snapshot.paramMap.get('id');
    this.http.get<any>("/api/project/documents/" + id)
      .subscribe(data => {
        this.documents = data.documents.filter(document => document.mime == 'application/pdf');
        this.images = data.documents.filter(document => document.mime.startsWith('image/'));

        //add some random stuff after url to prevent caching of images
        this.images.forEach(function (image) {
          image.thumbHref += '?' + self.random()
          image.origHref += '?' + self.random()
        });
      });
  }

  toggleDownloadMark(document: DocumentViewModel): void {
    document.markedForDownload = !document.markedForDownload;
  }

  editDocumentCost(document: DocumentViewModel): void {
    //TODO, modal with edit cost for this document
  }

  downloadFiles(): void {
    let documentsMarkedForDownload = this.documents.filter((x) => x.markedForDownload === true);
    let imagesMarkedForDownload = this.images.filter((x) => x.markedForDownload === true);
    let allDocumentsMarkedForDownload = documentsMarkedForDownload.concat(imagesMarkedForDownload)
    var ids = allDocumentsMarkedForDownload.map(item => item.id).filter((value, index, self) => self.indexOf(value) === index);
    window.location.href = 'api/project/downloadDocuments/?ids=' + ids.join(',');
    this.removeDownloadMarks()
  }

  removeDownloadMarks(): void {
    this.documents.forEach(function (document) {
      document.markedForDownload = false;
    });
    this.images.forEach(function (image) {
      image.markedForDownload = false;
    });
  }

  receiveMessage(message) {
    if (message === Messages.ReloadDocuments) {
      this.getDocuments()
    }
  }

  ngAfterViewInit() {
    //reload documents when modal closes with success
    this.ngxSmartModalService.getModal('projectModalDocument').onClose.subscribe((modal: NgxSmartModalComponent) => {
      if (modal.getData().success === true) {
        this.getDocuments()
      }
    });
  }

  ngOnInit() {
    this.getDocuments()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
