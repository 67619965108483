import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { UserService } from '@app/services/user.service';

@Component({
  selector: 'app-user-modal-remove',
  templateUrl: './user-modal-remove.component.html',
  styleUrls: ['./user-modal-remove.component.css']
})
export class UserModalRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private userService: UserService
  ) { }

  id: number
  modalName: string = 'userModalRemove'

  clear(): void {
    this.id = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.id) {
      return;
    }

    this.userService.delete(this.id).subscribe(
      () => {
        this.toastr.success('Användaren har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort användaren.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.id = modal.getData()
    });
  }
}
