import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '@app/services/project.service';
import { MessageService } from '../../../../services/message.service';
import { Messages } from '../../../../models/enums/Messages';

@Component({
  selector: 'app-project-modal-cost-remove',
  templateUrl: './project-modal-cost-remove.component.html',
  styleUrls: ['./project-modal-cost-remove.component.css']
})
export class ProjectModalCostRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private messageService: MessageService
  ) { }

  projectCostId: number

  clear(): void {
    this.projectCostId = null
    this.ngxSmartModalService.get('projectModalCostRemove').removeData();
  }

  remove(): void {
    if (!this.projectCostId) {
      return;
    }

    this.projectService.deleteProjectCost(this.projectCostId).subscribe(
      () => {
        this.toastr.success('Kostnadsposten har tagits bort.');
        this.ngxSmartModalService.get('projectModalCostRemove').removeData();
        this.ngxSmartModalService.setModalData({ success: true }, 'projectModalCostRemove');
        this.ngxSmartModalService.close('projectModalCostRemove');
        this.messageService.sendMessage(Messages.ReloadProjectStats)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort kostnadsposten.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal('projectModalCostRemove').onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var data = modal.getData()
      this.projectCostId = data.projectCostId
    });
  }

}
