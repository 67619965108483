import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { GravellogService } from '@app/services/gravellog.service';
import { GravelLogEditViewModel } from '@app/models/GravelLogEditViewModel';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';
import { MessageService } from '../../../../services/message.service';
import { Messages } from '../../../../models/enums/Messages';

@Component({
  selector: 'app-project-modal-gravellog-edit',
  templateUrl: './project-modal-gravellog-edit.component.html',
  styleUrls: ['./project-modal-gravellog-edit.component.css']
})
export class ProjectModalGravellogEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private gravellogService: GravellogService,
    private toastr: ToastrService,
    private datePickerDefaultOptions: DatepickerDefaultOptions,
    private messageService: MessageService
  ) {
    this.formGroup = this.formBuilder.group({
      'id': [''],
      'projectId': ['', [Validators.required]],
      'gravelTypeId': [''],
      'distanceId': [''],
      'supplierId': [''],
      'userId': ['', [Validators.required]],
      'weight': [''],
      'date': [''],
      'note': [''],
      'rangering': [''],
      'trailer': [''],
      'invoiced': [''],
    })
  }

  defaultValues = {
    id: null,
    projectId: null,
    gravelTypeId: null,
    distanceId: null,
    supplierId: null,
    userId: null,
    weight: 0,
    date: new Date().toJSON(),
    note: "",
    rangering: false,
    trailer: false,
    invoiced: false
  }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  date: Date
  projects: SimpleViewModel[]
  gravelTypes: SimpleViewModel[]
  gravelDistances: SimpleViewModel[]
  gravelSuppliers: SimpleViewModel[]
  users: SimpleViewModel[]
  isNew: boolean = false
  modalName: string = 'projectModalGravellogEdit';
  formGroup: FormGroup
  get form() { return this.formGroup.controls; }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.formGroup.reset(this.defaultValues)
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.gravellogService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Grusloggen har sparats.')
        this.messageService.sendMessage(Messages.ReloadProjectStats)
        this.closeModalWithSuccess()
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.toastr.error('Det gick inte att spara grusloggen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.gravellogService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Grusloggen har ändrats.');
        this.messageService.sendMessage(Messages.ReloadProjectStats)
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att uppdatera grusloggen.');
      },
      () => {
        //complete
      }
    );
  }

  getUsers(): void {
    this.http.get<SimpleViewModel[]>("/api/user/getAll")
      .subscribe(data => {
        this.users = data;
      });
  }

  getProjects(): void {
    this.http.get<SimpleViewModel[]>("/api/project/getAll")
      .subscribe(data => {
        this.projects = data;
      });
  }

  getGravelTypes(): void {
    this.http.get<SimpleViewModel[]>("/api/gravellog/types")
      .subscribe(data => {
        this.gravelTypes = data;
      });
  }

  getGravelDistances(): void {
    this.http.get<SimpleViewModel[]>("/api/gravellog/distances")
      .subscribe(data => {
        this.gravelDistances = data;
      });
  }

  getGravelSuppliers(): void {
    this.http.get<SimpleViewModel[]>("/api/gravellog/suppliers")
      .subscribe(data => {
        this.gravelSuppliers = data;
      });
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.getProjects()
      this.getGravelTypes()
      this.getGravelDistances()
      this.getGravelSuppliers()
      this.getUsers()

      let data = modal.getData();

      if (data.userId) {
        this.defaultValues.userId = data.userId
      }
      if (data.projectId) {
        this.defaultValues.projectId = data.projectId
      }

      let formValues = this.defaultValues
      if (!data.id) {
        this.isNew = true
        this.formGroup.reset(formValues)
      } else {
        this.isNew = false
        this.gravellogService.getUpdate(data.id).subscribe(
          (gravelLog: GravelLogEditViewModel) => {
            this.formGroup.patchValue(gravelLog)
            this.date = new Date(gravelLog.date)
            this.formGroup.patchValue({ date: new Date(gravelLog.date) })
          },
          (error) => {
            this.toastr.error("Det gick inte att hämta grusloggen.")
          },
          () => {
            //complete
          }
        )
      }
    });
  }

}
