
// Auto generated by typewriter


export class UserMachineReportViewModel {
    
    fromDate: Date = null;
    toDate: Date = null;
    userIds: number[] = [];
    workTypeIds: number[] = [];
    machineIds: number[] = [];
    customerIds: number[] = [];
    invoiced: boolean = false;
    notInvoiced: boolean = false;
    service: boolean = false;
    repair: boolean = false;
}
