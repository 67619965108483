import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GravelLogsDatatableComponent } from '@app/datatables/gravel-logs-datatable/gravel-logs-datatable.component';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-user-gravel-logs',
  templateUrl: './user-gravel-logs.component.html',
  styleUrls: ['./user-gravel-logs.component.css']
})
export class UserGravelLogsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute
  ) { }

  @ViewChild(GravelLogsDatatableComponent) gravelLogDatatable;
  userId: number = null

  add(): void {
    let data = {
      id: null,
      projectId: null,
      userId: this.userId
    }
    this.ngxSmartModalService.getModal('projectModalGravellogEdit').removeData()
    this.ngxSmartModalService.getModal('projectModalGravellogEdit').setData(data)
    this.ngxSmartModalService.getModal('projectModalGravellogEdit').open()
  }

  ngOnInit() {
    this.userId = Number(this.route.snapshot.paramMap.get('id'))
    this.gravelLogDatatable.userId = this.userId
  }

}
