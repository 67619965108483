import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProjectService } from '@app/services/project.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-modal-archive',
  templateUrl: './project-modal-archive.component.html',
  styleUrls: ['./project-modal-archive.component.css']
})
export class ProjectModalArchiveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) { }

  archive() {
    let id = this.route.snapshot.paramMap.get('id');
    this.projectService.archive(Number(id)).subscribe(
      () => {
        this.toastr.success('Projektet har arkiverats.');
        this.ngxSmartModalService.close('projectModalArchive');
      },
      (error) => {
        this.toastr.error('Det gick inte att arkivera projektet.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

}
