import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RepairLogService } from '@app/services/repairLog.service';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { RepairLogEditViewModel } from '@app/models/RepairLogEditViewModel';
import { DatepickerDefaultOptions } from '../../DatepickerDefaultOptions';

@Component({
  selector: 'app-repair-log-edit-modal',
  templateUrl: './repair-log-edit-modal.component.html',
  styleUrls: ['./repair-log-edit-modal.component.css']
})
export class RepairLogEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private repairLogService: RepairLogService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) {
    this.repairLogEditForm = this.formBuilder.group({
      id: [''],
      employeeId: [''],
      projectId: [''],
      machineId: [''],
      projectTitle: [''],
      mileage: ['',
        [
          Validators.required,
          Validators.min(0)
        ]
      ],
      time: ['',
        [
          Validators.required,
          Validators.min(0)
        ]
      ],
      date: [''],
      note: [''],
    });
  }

  repairLogEditForm: FormGroup
  modalName: string = 'repairLogEditModal';
  date: Date
  datepickerConfig = this.datePickerDefaultOptions.defaults

  get form() {
    return this.repairLogEditForm.controls;
  }

  resetForm() {
    const defaultValues = {
      id: 0,
      employeeId: null,
      projectId: null,
      machineId: null,
      projectTitle: '',
      mileage: 0,
      time: 0,
      date: new Date().toLocaleDateString('sv-SE'),
      note: '',
    };
    this.repairLogEditForm.reset(defaultValues);
  }

  employees: SimpleViewModel[]
  projects: SimpleViewModel[]
  isNew: boolean = false;

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.repairLogService.save(this.repairLogEditForm.value).subscribe(
      () => {
        this.toastr.success('Reparationen har sparats.');
        this.clear()
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att spara reparationen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.repairLogService.update(this.repairLogEditForm.value).subscribe(
      () => {
        this.toastr.success('Reparationen har sparats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att uppdatera reparationen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      let modalData: RepairLogEditViewModel = modal.getData();
      if (!modalData.id) {
        this.isNew = true
        this.resetForm()
        this.repairLogEditForm.controls['machineId'].patchValue(modalData.machineId)
      }
      else {
        this.isNew = false;
        this.repairLogEditForm.setValue(modalData)
        //convert UTC to local time
        this.date = new Date(modalData.date)
        this.repairLogEditForm.patchValue({ date: new Date(modalData.date) })
      }

      this.http.get<SimpleViewModel[]>("/api/userProfile/getActiveEmployees")
        .subscribe(data => {
          this.employees = data;
        })

      this.http.get<SimpleViewModel[]>("/api/project/getAll")
        .subscribe(data => {
          this.projects = data;
        });

    })
  }

}
