import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from '@app/services/report.service';
import { ProjectReportViewModel } from '@app/models/ProjectReportViewModel';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-project-modal-report',
  templateUrl: './project-modal-report.component.html',
  styleUrls: ['./project-modal-report.component.css']
})
export class ProjectModalReportComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute,
    private reportService: ReportService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) { }

  isPrinting: boolean = false
  datepickerConfig = this.datePickerDefaultOptions.defaults
  report: ProjectReportViewModel = new ProjectReportViewModel()

  print() {
    let self = this
    this.isPrinting = true
    this.reportService.downloadPdf(
      'api/report/project',
      this.report,
      'Projektsammanställning_' + +new Date() + '.pdf', function (success) {
        self.isPrinting = false
      })
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.report.id = Number(id)
  }
}
