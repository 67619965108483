import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { PersonalTimeService } from '@app/services/personalTime.service';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ActivatedRoute } from '@angular/router';
import { PersonalTimeEditViewModel } from '@app/models/PersonalTimeEditViewModel';
import { DatepickerDefaultOptions } from '../../../../DatepickerDefaultOptions';

@Component({
  selector: 'app-user-time-modal-edit',
  templateUrl: './user-time-modal-edit.component.html',
  styleUrls: ['./user-time-modal-edit.component.css']
})
export class UserTimeModalEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private personalTimeService: PersonalTimeService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private datePickerDefaultOptions: DatepickerDefaultOptions
) { }

  datepickerConfig = this.datePickerDefaultOptions.defaults
  date: Date
  isNew: boolean = false;
  timeTypes: SimpleViewModel[]
  modalName: string = 'userTimeModalEdit'
  formGroup = this.formBuilder.group({
    id: [''],
    userProfileId: ['', [Validators.required]],
    timeTypeId: ['', [Validators.required]],
    time: ['', [Validators.required]],
    date: ['', [Validators.required]],
    note: [''],
    isProcessedForPayment: ['']
  });
  formDefaults = {
    userProfileId: Number(this.route.snapshot.paramMap.get('id')),
    isProcessedForPayment: false
  }
  get form() { return this.formGroup.controls; }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.formGroup.reset();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.personalTimeService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Tidsloggen har sparats.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.formGroup.reset();
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att spara tidsloggen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.personalTimeService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Tidsloggen har sparats.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att uppdatera tidsloggen.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    let self = this
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var id = modal.getData();
      this.http.get<SimpleViewModel[]>("/api/timeType/getAll")
        .subscribe(data => {
          this.timeTypes = data;
        });

      if (!id) {
        this.isNew = true;
        this.formGroup.reset(this.formDefaults)
      } else {
        this.isNew = false;
        this.http.get<PersonalTimeEditViewModel>("/api/personalTime/update/?id=" + id)
          .subscribe(timeLog => {
              this.formGroup.patchValue(timeLog)
              //convert UTC to local time
              this.date = new Date(timeLog.date)
              this.formGroup.patchValue({ date: new Date(timeLog.date) })
          });
      }
    });
  }

}
