import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { GravelPriceDataTableViewModel } from '@app/models/GravelPriceDataTableViewModel';
import { GravelPriceEditViewModel } from '@app/models/GravelPriceEditViewModel';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-gravel-prices-datatable',
    templateUrl: './gravel-prices-datatable.component.html',
    styleUrls: ['./gravel-prices-datatable.component.css']
})
export class GravelPricesDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private router: Router,
        private http: HttpClient,
        private auth: AuthenticationService
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    gravelPricesDtOptions: DataTables.Settings = {};
    gravelPrices: GravelPriceDataTableViewModel[]
    modalEdit: string = 'gravelPriceEditModal';

    edit(gravelPrice: GravelPriceDataTableViewModel): void {
        this.http.get<GravelPriceEditViewModel>("/api/article/updateGravelPrice/?id=" + gravelPrice.gravelDeliveryPriceId)
            .subscribe(gravelPrice => {
                this.ngxSmartModalService.setModalData(gravelPrice, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            })
    }

    ngOnInit() {
        this.initDatatable()
    }

    ngAfterViewInit() {
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.gravelPricesDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                    self.http.get<DataTablesResponse>(`api/article/GravelPricesDatatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.gravelPrices = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
                columns: [
                    { data: 'articleNumber' },
                    { data: 'name' },
                    { data: 'distance' },
                    { data: 'isTrailer' }
                ]
            }
        }
    }
}
