import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DataTableDirective } from 'angular-datatables';
import { ProjectControlDataTableViewModel } from '@app/models/ProjectControlDataTableViewModel';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { HttpClient } from '@angular/common/http';
import { ControlDataTableViewModel } from '@app/models/ControlDataTableViewModel';
import { DataTablesRequest } from '@app/models/DataTablesRequest';

@Component({
    selector: 'app-controls-datatable',
    templateUrl: './controls-datatable.component.html',
    styleUrls: ['./controls-datatable.component.css']
})
export class ControlsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private http: HttpClient
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    controlsDtOptions: DataTables.Settings = {};
    controls: ControlDataTableViewModel[]
    modalEdit: string = 'controlEditModal'
    modalRemove: string = 'controlRemoveModal'

    edit(control: ControlDataTableViewModel): void {
        this.ngxSmartModalService.resetModalData(this.modalEdit)

        this.http.get<ControlDataTableViewModel>("/api/control/update/" + control.id)
            .subscribe(data => {
                this.ngxSmartModalService.setModalData(data, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            });
    }

    remove(control: ControlDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(control.id), this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    ngOnInit() {
        this.initDatatable()
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.controlsDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                    self.http.get<DataTablesResponse>(`api/control/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.controls = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: response.draw
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'id' },
                { data: 'name' }
            ]
        }
    }

    ngAfterViewInit() {
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    }


    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

}
