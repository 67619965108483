import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private url = '/api/report/';
  constructor(private http: HttpClient) { }
  userMachine(id: number): Observable<any> {
    return this.http.get<any>(this.url + `userMachine`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }

  downloadPdf(url: string, data: any, fileName: string, callback?: any): void {
    var deferred = $.Deferred()
    var xhr = new XMLHttpRequest();
    xhr.open('post', url, true);
    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhr.responseType = 'blob';
    xhr.send(JSON.stringify(data));

    xhr.onloadend = function (data: any) {
      if (this.status == 200) {
        var blob = new Blob([this.response], { type: 'application/pdf' });
        let a = document.createElement("a");
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        if (callback) {
          callback(true)
        }
      } else {
        if (callback) {
          callback(false)
        }
      }
    };

    deferred.resolve()
  }
}
