import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@app/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { UserProfileViewModel } from '@app/models/UserProfileViewModel';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService
  ) { }


  user: UserProfileViewModel = new UserProfileViewModel

  ngOnInit() {
    let id = Number(this.route.snapshot.paramMap.get('id'))

    this.userService.get(id).subscribe(
      (data: any) => {
        this.user = data;
      },
      (error) => {
        this.toastr.error('Det gick inte att hämta användaren.');
      },
      () => {
        //complete
      }
    );
  }

}
