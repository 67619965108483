import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectTimeDatatableComponent } from '@app/pages/project/datatables/project-time-datatable/project-time-datatable.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-project-time-datatable',
  templateUrl: './user-project-time-datatable.component.html',
  styleUrls: ['./user-project-time-datatable.component.css']
})
export class UserProjectTimeDatatableComponent implements OnInit {

  constructor(
    private route: ActivatedRoute
  ) { }

  @ViewChild(ProjectTimeDatatableComponent) projectTimeDatatable;

  ngOnInit() {
    let userId = Number(this.route.snapshot.paramMap.get('id'))
    this.projectTimeDatatable.userId = userId
  }

}
