﻿
// Auto generated by typewriter


export class SalaryReportViewModel {
    
    fromDate: Date = null;
    toDate: Date = null;
    userIds: number[] = [];
    timeTypeIds: number[] = [];
}
