import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { MachineInfoViewModel } from '../../../models/MachineInfoViewModel';

@Component({
  selector: 'app-machine-info',
  templateUrl: './machine-info.component.html',
  styleUrls: ['./machine-info.component.css']
})
export class MachineInfoComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient
  ) { }

  machineInfo = new MachineInfoViewModel
  machineId: number

  ngOnInit() {
    //machineId should be set by parent component
    this.http.get<MachineInfoViewModel>("/api/machine/info/" + this.machineId)
      .subscribe(data => {
        this.machineInfo = data;
      });
  }

}
