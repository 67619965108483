import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '@app/services/customer.service';
import { SimpleViewModel } from '../../../../models/SimpleViewModel';

@Component({
  selector: 'app-customer-modal-edit',
  templateUrl: './customer-modal-edit.component.html',
  styleUrls: ['./customer-modal-edit.component.css']
})
export class CustomerModalEditComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private toastr: ToastrService) { }

  isNew: boolean = false;
  modalName: string = 'customerModalEdit'
  formGroup = this.formBuilder.group({
    id: [''],
    customerNumber: [''],
    name: ['', [Validators.required]],
    address: [''],
    addressAlt: [''],
    zipCode: [''],
    city: [''],
    email: [''],
    website: [''],
    phoneNumber: [''],
    reference: [''],
    priceListId: ['']
  });
  get form() { return this.formGroup.controls; }

  pricelists: SimpleViewModel[];

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.formGroup.reset();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.customerService.save(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Kunden har sparats.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.formGroup.reset();
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att spara kunden.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.customerService.update(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Kunden har sparats.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.toastr.error('Det gick inte att uppdatera kunden.');
      },
      () => {
        //complete
      }
    );
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.http.get<SimpleViewModel[]>("/api/customer/getAllPricelists")
      .subscribe(data => {
        this.pricelists = data;
      });

    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      var data = modal.getData();

      //edit, otherwise its a new customer
      if (data !== undefined) {
        this.isNew = false;
        this.formGroup.setValue(data);
      } else {
        this.isNew = true;
        this.formGroup.reset();
      }
    });
  }

}
