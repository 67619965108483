import { Component, OnInit, ViewChild } from '@angular/core';
import { MachineInfoComponent } from '../machine-info/machine-info.component';
import { ActivatedRoute } from '@angular/router';
import { ServiceLogsDatatableComponent } from '../../../datatables/service-logs-datatable/service-logs-datatable.component';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { RepairLogsDatatableComponent } from '../../../datatables/repair-logs-datatable/repair-logs-datatable.component';
import { RepairLogEditViewModel } from '../../../models/RepairLogEditViewModel';

@Component({
  selector: 'app-machine-details',
  templateUrl: './machine-details.component.html',
  styleUrls: ['./machine-details.component.css']
})
export class MachineDetailsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute
  ) { }

  @ViewChild(MachineInfoComponent) machineInfo;
  @ViewChild(ServiceLogsDatatableComponent) serviceLogsDataTable;
  @ViewChild(RepairLogsDatatableComponent) repairLogsDataTable;

  machineId: number = null

  ngOnInit() {
    this.machineId = Number(this.route.snapshot.paramMap.get('id'))
    this.machineInfo.machineId = this.machineId;
    this.serviceLogsDataTable.machineId = this.machineId;
    this.repairLogsDataTable.machineId = this.machineId;
  }

  addRepairLog(): void {
    let modal = 'repairLogEditModal'
    this.ngxSmartModalService.resetModalData(modal)
    let data = new RepairLogEditViewModel()
    data.machineId = this.machineId
    this.ngxSmartModalService.setModalData(data, modal);
    this.ngxSmartModalService.open(modal);
  }

}
