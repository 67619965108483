﻿
// Auto generated by typewriter


export class UserWagesViewModel {
    
    userId: number = 0;
    fromDate: Date = null;
    toDate: Date = null;
    printAll: boolean = false;
    markAsHandled: boolean = false;
}
