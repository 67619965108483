import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '@app/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';

@Component({
  selector: 'app-project-modal-remove',
  templateUrl: './project-modal-remove.component.html',
  styleUrls: ['./project-modal-remove.component.css']
})
export class ProjectModalRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private projectService: ProjectService,
    private route: ActivatedRoute
  ) { }

  projectId: number
  modalName: string = 'projectModalRemove'

  clear(): void {
    this.projectId = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.projectId) {
      return;
    }

    this.projectService.delete(this.projectId).subscribe(
      () => {
        this.toastr.success('Projektet har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort projektet.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.projectId = modal.getData()
    });
  }

}
