import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net'
import { ReportService } from '@app/services/report.service';
import { DataTableDirective } from 'angular-datatables';
import { ProjectControlDataTableViewModel } from '../../../../models/ProjectControlDataTableViewModel';
import { DatatableDefaultOptions } from '../../../../DatatableDefaultOptions';
import { AuthenticationService } from '../../../../services/authentication.service';
import { DataTablesRequest } from '../../../../models/DataTablesRequest';
import { DataTablesResponse } from '../../../../models/DataTablesResponse';

@Component({
    selector: 'app-project-controls-datatable',
    templateUrl: './project-controls-datatable.component.html',
    styleUrls: ['./project-controls-datatable.component.css']
})
export class ProjectControlsDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private reportService: ReportService,
        private route: ActivatedRoute,
        private auth: AuthenticationService,
        private dataTableOptions: DatatableDefaultOptions,
        private http: HttpClient
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    projectControlDtOptions: DataTables.Settings = {};
    projectControls: ProjectControlDataTableViewModel[]
    modalRemove: string = 'projectModalControlRemove'
    detailsModal: string = 'projectModalControlDetails'
    addModal: string = 'projectModalControlAdd'
    projectId: number = null //set from parent component
    isPrinting: boolean = false

    ngOnInit() {
        this.projectId = Number(this.route.snapshot.paramMap.get('id'))
        this.initDatatable()
    }

    ngAfterViewInit() {
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
        this.reloadDatatableOnModalCloseSuccess(this.addModal)
        this.reloadDatatableOnModalCloseSuccess(this.addModal)
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    show(control: ProjectControlDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(control.id, this.detailsModal);
        this.ngxSmartModalService.getModal(this.detailsModal).open();
    }

    remove(control: ProjectControlDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(control.id, this.modalRemove);
        this.ngxSmartModalService.getModal(this.modalRemove).open();
    }

  report(control: ProjectControlDataTableViewModel): void {
    let self = this
    this.isPrinting = true
        this.reportService.downloadPdf(
            `api/report/projectControl?projectId=${this.projectId}&projectControlId=${control.id}`,
            null,
          "Egenkontroll_" + +new Date() + '.pdf',
          function (success) {
            self.isPrinting = false
          })
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.projectControlDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                        + (self.projectId ? "&projectId=" + self.projectId : "")
                    self.http.get<DataTablesResponse>(`api/ProjectControl/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.projectControls = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
            },
            columns: [
                { data: 'name' },
                { data: 'description' }
            ]
        }
    }
}
