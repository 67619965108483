import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SnowAreaService } from '@app/services/snowArea.service';
import { SnowAreaEditViewModel } from '@app/models/SnowAreaEditViewModel';

@Component({
  selector: 'app-snow-area-edit-modal',
  templateUrl: './snow-area-edit-modal.component.html',
  styleUrls: ['./snow-area-edit-modal.component.css']
})
export class SnowAreaEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private snowAreaService: SnowAreaService
  ) {
    this.snowAreaEditForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]]
    });
  }

  snowAreaEditForm: FormGroup
  modalName: string = 'snowAreaEditModal';

  get form() {
    return this.snowAreaEditForm.controls;
  }

  resetForm() {
    const defaultValues = {
      id: null,
      name: ''
    };
    this.snowAreaEditForm.reset(defaultValues);
  }

  isNew: boolean = false;

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.snowAreaService.save(this.snowAreaEditForm.value).subscribe(
      () => {
        this.toastr.success('Området har sparats.');
        this.clear()
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att spara området.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.snowAreaService.update(this.snowAreaEditForm.value).subscribe(
      () => {
        this.toastr.success('Området har sparats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att uppdatera området.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      let modalData: SnowAreaEditViewModel = modal.getData();
      if (!modalData || !modalData.id) {
        this.isNew = true
        this.resetForm()
      }
      else {
        this.isNew = false;
        this.snowAreaEditForm.setValue(modalData)
      }
    })
  }

}
