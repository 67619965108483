﻿
// Auto generated by typewriter


export class ControlItemViewModel {
    
    id: number = null;
    controlId: number = null;
    name: string = null;
    hasImage: boolean = false;
    hasText: boolean = false;
}
