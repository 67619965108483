import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SignIn } from '../models/user/SignIn';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {

  private url = '/api/user/';
  public currentUser: SignIn;
  public isAdmin: boolean

  constructor(private http: HttpClient) {
    this.currentUser = null;
  }

  ngOnDestroy() {  }

  public isUserAuthenticated(): boolean {
    if (this.currentUser) {
      return true;
    }
    else {
      return false;
    }
    
  }

  public setIsAdmin() {
    let adminRoles: string[] = ["Projektansvarig", "Admin", "Super Admin", "Administrators"]
    if (this.currentUser) {
      this.isAdmin = this.currentUser.roles.some(x => adminRoles.indexOf(x) !== -1)
    }
  }

  public login(username: string, password: string) {
    return this.http.post<any>(this.url + 'signin', { username, password })
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        map(user => {
          if (user) {
            this.currentUser = user;
            this.setIsAdmin();
          }

        return user;
      }));
  }

  getCurrentUser(): Observable<SignIn> {
    return this.http.get<SignIn>(this.url + 'currentUser'); 
  }

  logout() {
    this.currentUser = null;
    this.http.get(this.url + 'logout').subscribe(data => location.href = "/");
  }
}
