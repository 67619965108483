import { Component, OnInit, ViewChild } from '@angular/core';
import { SingleProjectStatsViewModel } from '@app/models/SingleProjectStatsViewModel';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '@app/services/project.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectChartComponent } from '../project-chart/project-chart.component';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { Messages } from '../../../models/enums/Messages';

@Component({
  selector: 'app-project-details-stats',
  templateUrl: './project-details-stats.component.html',
  styleUrls: ['./project-details-stats.component.css']
})
export class ProjectDetailsStatsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private messageService: MessageService
  ) {
    this.subscription = this.messageService.getMessage().subscribe(message => {
      if (message.text == Messages.ReloadProjectStats) {
        this.getStats()
      }
    });
  }

  @ViewChild(ProjectChartComponent) projectChart
  stats: SingleProjectStatsViewModel = new SingleProjectStatsViewModel
  projectId: number = null
  priceListId: number = 4
  percent: number = 100
  subscription: Subscription;
  isLoading = false

  //changePriceList(priceListId: number) {
  //  this.priceListId = priceListId
  //  this.messageService.sendMessage(Messages.ReloadProjectStats)
  //}

  getStats() {
    this.projectId = this.route.snapshot.params['id']
    this.isLoading = true
    this.projectService.getStats(this.projectId).subscribe((data) => {
      this.stats = data;
      this.percent = data.workedHours / data.budgetHours
      this.percent = Number((this.percent * 100).toFixed(1))
      this.isLoading = false
      if (isNaN(this.percent)) {
        this.percent = 100
      }
    }, (error) => {
        this.toastr.error('Det gick inte att hämta statistik för projektet.');
        this.isLoading = false
    }, () => {
      //complete
    });
  }

  ngOnInit() {
    this.getStats()
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
