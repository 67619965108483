﻿
// Auto generated by typewriter


export class SnowAreaReportViewModel {
    
    id: number = 0;
    fromDate: Date = null;
    toDate: Date = null;
    markAsHandled: boolean = false;
}
