﻿
// Auto generated by typewriter


export class ProjectStatsViewModel {
    
    lastWeek: number = 0;
    active: number = 0;
    passive: number = 0;
    total: number = 0;
}
