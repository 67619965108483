import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { DatatableDefaultOptions } from '@app/DatatableDefaultOptions';
import { DataTableDirective } from 'angular-datatables';
import { DataTablesResponse } from '@app/models/DataTablesResponse';
import { MachineDataTableViewModel } from '@app/models/MachineDataTableViewModel';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MachineEditViewModel } from '@app/models/MachineEditViewModel';
import { DataTablesRequest } from '@app/models/DataTablesRequest';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
    selector: 'app-machines-datatable',
    templateUrl: './machines-datatable.component.html',
    styleUrls: ['./machines-datatable.component.css']
})
export class MachinesDatatableComponent implements OnInit {

    constructor(
        public ngxSmartModalService: NgxSmartModalService,
        private dataTableOptions: DatatableDefaultOptions,
        private router: Router,
        private http: HttpClient,
        private auth: AuthenticationService
    ) { }

    @ViewChild(DataTableDirective)
    private datatableElement: DataTableDirective;
    machinesDtOptions: DataTables.Settings = {};
    machines: MachineDataTableViewModel[]
    modalEdit: string = 'machineModalEdit';
    modalRemove: string = 'machineModalRemove'

    edit(machine: MachineDataTableViewModel): void {
        this.http.get<MachineEditViewModel>("/api/machine/update/?id=" + machine.id)
            .subscribe(machine => {
                this.ngxSmartModalService.setModalData(machine, this.modalEdit);
                this.ngxSmartModalService.open(this.modalEdit);
            })
    }

    show(machine: MachineDataTableViewModel): void {
        this.router.navigate(['machine/' + machine.id])
    }

    remove(machine: MachineDataTableViewModel): void {
        this.ngxSmartModalService.setModalData(Number(machine.id), this.modalRemove);
        this.ngxSmartModalService.open(this.modalRemove);
    }

    ngOnInit() {
        this.initDatatable()
    }

    ngAfterViewInit() {
        this.reloadDatatableOnModalCloseSuccess(this.modalEdit)
        this.reloadDatatableOnModalCloseSuccess(this.modalRemove)
    }

    reloadDatatableOnModalCloseSuccess(modalName: string) {
        this.ngxSmartModalService.getModal(modalName).onClose.subscribe((modal: NgxSmartModalComponent) => {
            let data = modal.getData()
            if (data && data.success === true) {
                this.reloadDatatable()
            }
        });
    }

    reloadDatatable(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.ajax.reload());
    }

    initDatatable(): void {
        let self = this
        let lastDraw = 0
        this.machinesDtOptions = {
            ...this.dataTableOptions.defaults, ...{
                ajax: (dataTablesParameters: DataTablesRequest, callback: (data: DataTablesResponse) => any) => {
                    let request = JSON.stringify(dataTablesParameters)
                    self.http.get<DataTablesResponse>(`api/machine/Datatable?request=${request}`).subscribe(response => {
                        if (response.draw < lastDraw) {
                            return;
                        }
                        lastDraw = response.draw
                        self.machines = response.data;
                        let callbackResponse: DataTablesResponse = {
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.recordsFiltered,
                            data: [],
                            draw: 0
                        }
                        callback(callbackResponse);
                    });
                },
                columns: [
                    { data: 'articleNumber' },
                    { data: 'name' },
                    { data: 'type' }
                ]
            }
        }
    }
}
