import { Component, OnInit } from '@angular/core';
import { CustomerService } from '@app/services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';

@Component({
  selector: 'app-customer-modal-remove',
  templateUrl: './customer-modal-remove.component.html',
  styleUrls: ['./customer-modal-remove.component.css']
})
export class CustomerModalRemoveComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private toastr: ToastrService,
    private customerService: CustomerService
  ) { }

  id: number
  modalName: string = 'customerModalRemove'

  clear(): void {
    this.id = null
    this.ngxSmartModalService.get(this.modalName).removeData();
  }

  remove(): void {
    if (!this.id) {
      return;
    }

    this.customerService.delete(this.id).subscribe(
      () => {
        this.toastr.success('Kunden har tagits bort.')
        this.ngxSmartModalService.get(this.modalName).removeData()
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName)
        this.ngxSmartModalService.close(this.modalName)
      },
      (error) => {
        this.toastr.error('Det gick inte att ta bort kunden.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      this.id = modal.getData()
    });
  }
}
