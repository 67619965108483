import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { ToastrService } from 'ngx-toastr';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '@app/services/project.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-project-modal-control-add',
  templateUrl: './project-modal-control-add.component.html',
  styleUrls: ['./project-modal-control-add.component.css']
})
export class ProjectModalControlAddComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private projectService: ProjectService
  ) { }

  modalName: string = 'projectModalControlAdd'
  controls: SimpleViewModel[] = []

  formGroup = this.formBuilder.group({
    projectId: ['', [Validators.required]],
    controlId: ['', [Validators.required]],
    description: ['']
  });
  get form() { return this.formGroup.controls; }
  resetForm() {
    const defaultValues = {
      projectId: null,
      controlId: null,
      description: ''
    };
    this.formGroup.reset(defaultValues);
  }

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear()
    this.ngxSmartModalService.close(this.modalName)
  }

  save() : void {
    this.projectService.saveProjectControl(this.formGroup.value).subscribe(
      () => {
        this.toastr.success('Kontrollen har lagts till på projektet.');
        this.ngxSmartModalService.get(this.modalName).removeData();
        this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
        this.formGroup.reset();
        this.controls = []
        this.ngxSmartModalService.close(this.modalName);
      },
      (error) => {
        this.toastr.error('Det gick inte att lägga till kontrollen på projektet.');
      },
      () => {
        //complete
      }
    );
  }

  getProjectControls(): void {
    this.http.get<SimpleViewModel[]>("/api/control/getAll")
      .subscribe(data => {
        this.controls = data;
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      //var data = modal.getData()
      let id = this.route.snapshot.paramMap.get('id');
      this.resetForm()
      this.formGroup.patchValue({ projectId: id })
      this.getProjectControls()
    });
  }

}
