import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { ActivatedRoute } from '@angular/router';
import { SnowLogsDatatableComponent } from '@app/datatables/snow-logs-datatable/snow-logs-datatable.component';

@Component({
  selector: 'app-user-snow-logs',
  templateUrl: './user-snow-logs.component.html',
  styleUrls: ['./user-snow-logs.component.css']
})
export class UserSnowLogsComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private route: ActivatedRoute
  ) { }

  @ViewChild(SnowLogsDatatableComponent) snowLogDatatable;
  userId: number = null
  modalEdit: string = 'snowLogEditModal';

  add(): void {
    let data = {
      employeeId: this.userId,
    }
    this.ngxSmartModalService.getModal(this.modalEdit).removeData()
    this.ngxSmartModalService.getModal(this.modalEdit).setData(data)
    this.ngxSmartModalService.getModal(this.modalEdit).open()
  }

  ngOnInit() {
    this.userId = Number(this.route.snapshot.paramMap.get('id'))
    this.snowLogDatatable.userId = this.userId
  }

}
