import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { TimeTypeService } from '@app/services/timeType.service';
import { TimeTypeEditViewModel } from '@app/models/TimeTypeEditViewModel';

@Component({
  selector: 'app-time-type-edit-modal',
  templateUrl: './time-type-edit-modal.component.html',
  styleUrls: ['./time-type-edit-modal.component.css']
})
export class TimeTypeEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private timeTypeService: TimeTypeService
  ) {
    this.timeTypeEditForm = this.formBuilder.group({
      id: [''],
      name: ['', [ Validators.required ]],
      absence: ['']
    });
  }

  timeTypeEditForm: FormGroup
  modalName: string = 'timeTypeEditModal';

  get form() {
    return this.timeTypeEditForm.controls;
  }

  resetForm() {
    const defaultValues = {
      id: null,
      name: '',
      absence: false
    };
    this.timeTypeEditForm.reset(defaultValues);
  }

  isNew: boolean = false;

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    this.timeTypeService.save(this.timeTypeEditForm.value).subscribe(
      () => {
        this.toastr.success('Tidstypen har sparats.');
        this.clear()
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att spara tidstypen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    this.timeTypeService.update(this.timeTypeEditForm.value).subscribe(
      () => {
        this.toastr.success('Tidstypen har sparats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att uppdatera tidstypen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      let modalData: TimeTypeEditViewModel = modal.getData();
      if (!modalData || !modalData.id) {
        this.isNew = true
        this.resetForm()
      }
      else {
        this.isNew = false;
        this.timeTypeEditForm.setValue(modalData)
      }
    })
  }

}
