import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { UserProfileEditViewModel } from '../models/UserProfileEditViewModel';
import { UserProfileViewModel } from '../models/UserProfileViewModel';
import { SimpleViewModel } from '../models/SimpleViewModel';
import { UserStatsViewModel } from '@app/models/UserStatsViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = '/api/userProfile/';
  constructor(private http: HttpClient) { }

  getUserStats(id: number): Observable<UserStatsViewModel> {
    return this.http.get<UserStatsViewModel>(this.url + `stats?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }

  get(id: number): Observable<UserProfileViewModel> {
    return this.http.get<UserProfileViewModel>(this.url + `get?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  getRoles(): Observable<SimpleViewModel[]> {
    return this.http.get<SimpleViewModel[]>(this.url + `getRoles`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  save(something: UserProfileEditViewModel): Observable<any> {
    return this.http.post<any>(this.url + 'create', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  update(something: UserProfileEditViewModel): Observable<any> {
    return this.http.put<any>(this.url + 'update', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + `delete?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
}
