import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService, NgxSmartModalComponent } from 'ngx-smart-modal';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, Validators, FormArray, FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ServiceLogService } from '@app/services/serviceLog.service';
import { SimpleViewModel } from '@app/models/SimpleViewModel';
import { ServiceLogEditViewModel } from '@app/models/ServiceLogEditViewModel';
import { ServiceTypeAndLogViewModel } from '@app/models/ServiceTypeAndLogViewModel';
import { ServiceTypeViewModel } from '@app/models/ServiceTypeViewModel';
import { forEach } from '@angular/router/src/utils/collection';
import { DatepickerDefaultOptions } from '../../DatepickerDefaultOptions';

@Component({
  selector: 'app-service-log-edit-modal',
  templateUrl: './service-log-edit-modal.component.html',
  styleUrls: ['./service-log-edit-modal.component.css']
})
export class ServiceLogEditModalComponent implements OnInit {

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private serviceLogService: ServiceLogService,
    private datePickerDefaultOptions: DatepickerDefaultOptions
  ) {
    this.serviceLogEditForm = this.formBuilder.group({
      id: [''],
      machineId: [''],
      employeeId: ['',],
      mileage: ['',
        [
          Validators.required,
          Validators.min(0)
        ]
      ],
      time: ['',
        [
          Validators.required,
          Validators.min(0)
        ]
      ],
      date: [''],
      note: [''],
      services: ['']
    });
  }

  date: Date
  datepickerConfig = this.datePickerDefaultOptions.defaults
  serviceLogEditForm: FormGroup
  modalName: string = 'serviceLogEditModal';
  title: string = 'Lägg till servicelogg'

  get form() {
    return this.serviceLogEditForm.controls;
  }

  resetForm() {
    const defaultValues = {
      id: 0,
      machineId: null,
      employeeId: null,
      mileage: 0,
      time: 0,
      date: new Date().toLocaleDateString('sv-SE'),
      note: '',
      services: null
    };
    this.serviceLogEditForm.reset(defaultValues);
  }

  machines: SimpleViewModel[]
  employees: SimpleViewModel[]
  checkedServices: number[]
  serviceTypes: ServiceTypeViewModel[]
  isNew: boolean = false;

  clear(): void {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.resetForm();
  }

  close(): void {
    this.clear();
    this.ngxSmartModalService.setModalData({ success: false }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  closeModalWithSuccess() {
    this.ngxSmartModalService.get(this.modalName).removeData();
    this.ngxSmartModalService.setModalData({ success: true }, this.modalName);
    this.ngxSmartModalService.close(this.modalName);
  }

  save(): void {
    let checkedServices = this.getCheckedServices()
    this.serviceLogEditForm.controls['services'].patchValue(checkedServices)

    this.serviceLogService.save(this.serviceLogEditForm.value).subscribe(
      () => {
        this.toastr.success('Serviceloggen har sparats.');
        this.clear()
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att spara serviceloggen.');
      },
      () => {
        //complete
      }
    );
  }

  update(): void {
    let checkedServices = this.getCheckedServices()
    this.serviceLogEditForm.controls['services'].patchValue(checkedServices)

    this.serviceLogService.update(this.serviceLogEditForm.value).subscribe(
      () => {
        this.toastr.success('Serviceloggen har sparats.');
        this.closeModalWithSuccess()
      },
      (error) => {
        this.toastr.error('Det gick inte att uppdatera serviceloggen.');
      },
      () => {
        //complete
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.isNew === true) {
      this.save()
    } else {
      this.update();
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService.getModal(this.modalName).onOpen.subscribe((modal: NgxSmartModalComponent) => {
      let modalData: ServiceLogEditViewModel = modal.getData();
      if (!modalData) {
        this.isNew = true
        this.resetForm()
      }
      else {
        this.isNew = false;
        this.serviceLogEditForm.setValue(modalData)
        //convert UTC to local time
        this.date = new Date(modalData.date)
        this.serviceLogEditForm.patchValue({ date: new Date(modalData.date) })
      }

      this.http.get<SimpleViewModel[]>("/api/machine/getAll")
        .subscribe(data => {
          this.machines = data;
        });

      this.http.get<SimpleViewModel[]>("/api/userProfile/getActiveEmployees")
        .subscribe(data => {
          this.employees = data;
        });

      this.http.get<ServiceTypeViewModel[]>("/api/serviceLog/getServiceTypes")
        .subscribe(data => {
          this.serviceTypes = data;
          if (modalData && modalData.services) {
            this.setCheckedServices(modalData.services)
          }
        });

    })
  }

  setCheckedServices(services: number[]): void {
    for (let serviceType of this.serviceTypes) {
      if (services.includes(serviceType.id)) {
        serviceType.isChecked = true
      }
    }
  }

  getCheckedServices(): number[] {
    return this.serviceTypes.filter(x => x.isChecked).map(x => x.id)
  }
}
