import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ProjectControlPostViewModel } from '../models/ProjectControlPostViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProjectControlPostService {
  private url = '/api/projectControlPost/';
  constructor(private http: HttpClient) { }
  get(id: number): Observable<any> {
    return this.http.get<any>(this.url + `get?=id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  save(something: any): Observable<any> {
    return this.http.post<any>(this.url + 'create', something, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  update(vm: ProjectControlPostViewModel): Observable<ProjectControlPostViewModel> {
    console.log(vm)
    return this.http.put<ProjectControlPostViewModel>(this.url + 'update', vm, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + `delete?id=${id}`, httpOptions)
      .pipe(catchError(error => { return throwError(error) }));
  }
}
