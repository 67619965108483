import { Injectable } from '@angular/core';
import { ProjectEditViewModel } from '../models/ProjectEditViewModel';
import { ProjectReportViewModel } from '../models/ProjectReportViewModel';
import { ProjectCostViewModel } from '../models/ProjectCostViewModel';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ProjectControlViewModel } from '../models/ProjectControlViewModel';
import { SingleProjectStatsViewModel } from '../models/SingleProjectStatsViewModel';
import { ProjectControlPostViewModel } from '../models/ProjectControlPostViewModel';
import { InvoiceStatus } from '../models/enums/InvoiceStatus';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projectUrl = '/api/project/';
  private projectCostUrl = '/api/projectCost/';
  private projectControlUrl = '/api/projectControl/'

  constructor(private http: HttpClient) { }

  get(id: number): Observable<any> {
    return this.http.get<any>(this.projectUrl + 'get/' + id, httpOptions);
  }

  getStats(id: number): Observable<SingleProjectStatsViewModel> {
    return this.http.get<SingleProjectStatsViewModel>(this.projectUrl + 'stats?id=' + id, httpOptions);
  }

  archive(id: number): Observable<any> {
    return this.http.put<any>(this.projectUrl + 'archive/' + id, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  save(projectEditViewModel: ProjectEditViewModel): Observable<ProjectEditViewModel> {
    return this.http.post<ProjectEditViewModel>(this.projectUrl + 'create', projectEditViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  update(projectEditViewModel: ProjectEditViewModel): Observable<ProjectEditViewModel> {
    return this.http.put<ProjectEditViewModel>(this.projectUrl + 'update', projectEditViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(this.projectUrl + 'delete?id=' + id, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  printReport(projectPrintReportViewModel: ProjectReportViewModel): Observable<any> {
    return this.http.post<ProjectReportViewModel>(this.projectUrl + 'report', projectPrintReportViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  deleteDocument(id: number): Observable<any> {
    return this.http.post<any>(this.projectUrl + 'deleteDocument/' + id, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }
  
  toggleDocumentMark(id: number): Observable<any> {
    return this.http.post<any>(this.projectUrl + 'toggleDocumentMark/' + id, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  deleteProjectCost(id: number): Observable<any> {
    return this.http.delete<any>(this.projectCostUrl + 'delete/' + id, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  updateProjectCost(projectCostViewModel: ProjectCostViewModel): Observable<any> {
    return this.http.put<ProjectCostViewModel>(this.projectCostUrl + 'update', projectCostViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  saveProjectCost(projectCostViewModel: ProjectCostViewModel): Observable<any> {
    return this.http.post<ProjectCostViewModel>(this.projectCostUrl + 'create', projectCostViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  saveProjectCosts(projectCostViewModels: ProjectCostViewModel[]): Observable<any> {
    return this.http.post<ProjectCostViewModel[]>(this.projectCostUrl + 'createMany', projectCostViewModels, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  getControlPosts(projectControlId: number): Observable<any> {
    return this.http.get<ProjectControlPostViewModel[]>(this.projectControlUrl + 'getControlPosts?id=' + projectControlId, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  saveProjectControl(projectControlViewModel: ProjectControlViewModel): Observable<any> {
    return this.http.post<ProjectCostViewModel>(this.projectControlUrl + 'create', projectControlViewModel, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  saveCostDocument(costId: number, documentId: number): Observable<any> {
    return this.http.post<any>(this.projectCostUrl + `addDocument?costId=${costId}&documentId=${documentId}`, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }

  setReadyToInvoice(projectId: number): Observable<any> {
    return this.http.post<any>(this.projectUrl + `setReadyToInvoice?projectId=${projectId}`, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
    );
  }

  resetReadyToInvoice(projectId: number): Observable<any> {
    return this.http.post<any>(this.projectUrl + `resetReadyToInvoice?projectId=${projectId}`, httpOptions)
      .pipe(
        catchError(error => {
          return throwError(error)
        })
      );
  }
  //Om man vill hantera error direkt istället!
  //update(projectEditViewModel: ProjectEditViewModel): Observable<ProjectEditViewModel> {
  //  return this.http.put<ProjectEditViewModel>(this.projectUrl + 'update', projectEditViewModel, httpOptions)
  //    .pipe(
  //      tap(_ => this.toastr.success('Projektet sparat!')),
  //      catchError(this.handleError<ProjectEditViewModel>('update'))
  //    );
  //}

  //private handleError<T>(operation = 'operation', result?: T) {
  //  return (error: any): Observable<T> => {
  //    this.toastr.error(`${operation} failed: ${error.message}`);

  //    // Let the app keep running by returning an empty result.
  //    return of(result as T);
  //  };
  //}
}
