﻿
// Auto generated by typewriter


export class GravelReportViewModel {
    
    fromDate: Date = null;
    toDate: Date = null;
    supplierIds: number[] = [];
    includeHistory: boolean = false;
}
